import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'

import mq from 'styles/breakpoints'

export const FlexItem = styled.div`
  flex: 1;
  ${mq['sm']} {
    flex: 1 1 100%;
  }
`

export const Instruction = ({ title, link }) => {
  const { t } = useTranslation('instruction')

  return (
    <FlexItem style={{ textAlign: 'center' }}>
      <h2>{title}</h2>
      <a href={link}>{t('getStarted')}</a>
    </FlexItem>
  )
}
