import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

const FeedbackSection = () => {
  const { t } = useTranslation('feedback')

  // TODO: Switch this to generate an invite link using the Slack API (via link management service?)
  const slackInviteURL = process.env.GATSBY_SLACK_INVITE_URL
  // Link text is set in the actual paragraph localization header, tbr will be replaced by that but is included to remove the a1ly warnings
  const slackJoinLink = (
    <a href={slackInviteURL} target="_blank" rel="noreferrer">
      tbr
    </a>
  )
  const slackFeedbackLink = (
    <a
      href={`${process.env.GATSBY_SLACK_ROOT_URL}/messages/${process.env.GATSBY_SLACK_FEEDBACK_CHANNEL}`}
      target="_blank"
      rel="noreferrer"
    >
      tbr
    </a>
  )
  const slackTroubleshootingLink = (
    <a
      href={`${process.env.GATSBY_SLACK_ROOT_URL}/messages/${process.env.GATSBY_SLACK_TROUBLESHOOTING_CHANNEL}`}
      target="_blank"
      rel="noreferrer"
    >
      tbr
    </a>
  )

  return (
    <div>
      <p>
        <Trans t={t} i18nKey="paragraph1" components={[slackJoinLink]} />
      </p>
      <p>
        <Trans
          t={t}
          i18nKey="paragraph2"
          components={[slackFeedbackLink, slackTroubleshootingLink]}
        />
      </p>
    </div>
  )
}

export default FeedbackSection
