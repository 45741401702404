export const ANDROID_PLATFORM = 'android'
export const IOS_PLATFORM = 'ios'
export const MACOS_PLATFORM = 'mac'
export const WINDOWS_PLATFORM = 'windows'
export const OTHER_PLATFORM = 'other'

// With iOS 13+, iPad's user agent string is identical to Mac and feature detection has to be used for distinction
const hasIpadFeatures = () => {
  if (typeof window !== 'undefined') {
    const { maxTouchPoints } = window.navigator
    // TODO: Expand feature detection as needed

    return maxTouchPoints > 1
  }

  return false
}

export const getOS = () => {
  let os = OTHER_PLATFORM
  if (typeof window !== 'undefined') {
    const { userAgent } = window.navigator

    if (/iPhone|iPad/.test(userAgent)) {
      os = IOS_PLATFORM
    } else if (/Macintosh|Mac OS X/.test(userAgent)) {
      os = hasIpadFeatures() ? IOS_PLATFORM : MACOS_PLATFORM
    } else if (/Android/.test(userAgent)) {
      os = ANDROID_PLATFORM
    } else if (/Windows/.test(userAgent)) {
      os = WINDOWS_PLATFORM
    }
  }

  return os
}

export const isMobilePlatform = () =>
  getOS() === ANDROID_PLATFORM || getOS() === IOS_PLATFORM
