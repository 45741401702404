import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { graphql } from 'gatsby'
import { Flex } from 'rebass'

import Layout from 'components/layout'
import SEO from 'components/seo'
import LottieShape from 'components/animations/LottieShape'
import ConversationAnimation from 'components/animations/abstract/ConversationAnimation'
import LaunchAnimation from 'components/animations/abstract/LaunchAnimation'

import PlatformInstructionsSection from 'components/Beta/PlatformInstructionsSection'
import FeedbackSection from 'components/Beta/FeedbackSection'
import Step from 'components/Beta/Step'
import { FlexItem } from 'components/Beta/BetaComponents.js'
import { OpenLetterAnimationElement } from 'components/animations/letter/OpenLetterAnimationComponents'

const Beta = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const OPEN_LETTER_ANIMATION_DURATION = 2000
  const { t } = useTranslation('beta')
  const [isAnimationLoaded, setIsAnimationLoaded] = useState(false)
  useEffect(() => {
    setTimeout(() => setIsAnimationLoaded(true), OPEN_LETTER_ANIMATION_DURATION)
  }, [])

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title={t('title')} />
      <>
        <h1>{t('heading')}</h1>
        {!isAnimationLoaded ? (
          <OpenLetterAnimationElement />
        ) : (
          <>
            <Step anchor={'step1'} title={t('step1Heading')}>
              <Flex flexWrap="wrap" alignContent="center" alignItems="center">
                <FlexItem>
                  <FeedbackSection />
                </FlexItem>
                <FlexItem>
                  <LottieShape
                    animationData={ConversationAnimation}
                    size={320}
                  />
                </FlexItem>
              </Flex>
            </Step>
            <Step anchor="step2" title={t('step2Heading')}>
              <PlatformInstructionsSection />
            </Step>
            <Step anchor="step3" title={t('step3Heading')}>
              <>
                <p>{t('step3Body')}</p>
                <Flex justifyContent="center">
                  <FlexItem>
                    <LottieShape animationData={LaunchAnimation} size={450} />
                  </FlexItem>
                </Flex>
              </>
            </Step>
          </>
        )}
      </>
    </Layout>
  )
}

export default Beta

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
