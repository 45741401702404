import React from 'react'
import PropTypes from 'prop-types'

import {
  getOS,
  MACOS_PLATFORM,
  IOS_PLATFORM,
  ANDROID_PLATFORM,
  WINDOWS_PLATFORM,
  OTHER_PLATFORM,
} from 'components/common/DeviceOS'

const PlatformConditional = ({ android, ios, windows, mac, other }) => {
  const platform = getOS()

  switch (platform) {
    case MACOS_PLATFORM:
      return mac
    case IOS_PLATFORM:
      return ios
    case ANDROID_PLATFORM:
      return android
    case WINDOWS_PLATFORM:
      return windows
    case OTHER_PLATFORM:
    default:
      return other
  }
}

PlatformConditional.defaultProps = {
  android: <></>,
  ios: <></>,
  windows: <></>,
  mac: <></>,
  other: <></>,
}

PlatformConditional.propTypes = {
  android: PropTypes.element,
  ios: PropTypes.element,
  windows: PropTypes.element,
  mac: PropTypes.element,
  other: PropTypes.element,
}

export default PlatformConditional
