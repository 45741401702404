import React from 'react'
import { useTranslation } from 'react-i18next'
import { Flex } from 'rebass'

import PlatformConditional from 'components/common/PlatformConditional'
import { Instruction } from 'components/Beta/BetaComponents.js'

const PlatformInstructionsSection = () => {
  const { t } = useTranslation('instruction')

  const androidInstructions = (
    <Instruction
      title={t('android')}
      link={process.env.GATSBY_GOOGLE_PLAY_ANDROID_INVITE_URL}
    />
  )
  const iOSInstructions = (
    <Instruction
      title={t('ios')}
      link={process.env.GATSBY_TESTFLIGHT_INVITE_URL}
    />
  )
  const desktopAndroidInstructions = (
    <Instruction
      title={t('android')}
      link={process.env.GATSBY_GOOGLE_PLAY_WEB_INVITE_URL}
    />
  )
  const webInstructions = (
    <Instruction title={t('web')} link={process.env.GATSBY_SITE_SIGN_IN_PATH} />
  )
  const desktopInstructions = (
    <Flex
      flexWrap="wrap"
      alignContent="center"
      alignItems="center"
      justifyContent="space-evenly"
    >
      {desktopAndroidInstructions}
      {iOSInstructions}
      {webInstructions}
    </Flex>
  )
  return (
    <PlatformConditional
      ios={iOSInstructions}
      android={androidInstructions}
      windows={desktopInstructions}
      mac={desktopInstructions}
      other={desktopInstructions}
    />
  )
}

export default PlatformInstructionsSection
