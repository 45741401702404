const LaunchAnimation = {
  v: '5.2.1',
  fr: 30,
  ip: 0,
  op: 180,
  w: 1920,
  h: 1080,
  nm: 'launch',
  ddd: 0,
  assets: [
    {
      id: 'comp_0',
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: 'Path 20',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [32.692, 40.285, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [4.89, -9.91],
                        [-2.73, -1.28],
                        [-2.89, 3.6],
                        [0.96, 0.24],
                        [0.97, -2.64],
                        [0, 2.16],
                        [0.72, -2.4],
                        [-0.48, -1.2],
                        [0.96, -9.4],
                      ],
                      o: [
                        [0.96, 3.27],
                        [9.39, -5.52],
                        [2.89, -3.6],
                        [-0.96, -0.24],
                        [-0.96, 2.64],
                        [0, -2.16],
                        [-0.58, 1.92],
                        [-0.81, 2.16],
                        [-0.97, 9.41],
                      ],
                      v: [
                        [-15.541, 30.365],
                        [-6.151, 38.285],
                        [11.659, -26.975],
                        [15.269, -38.015],
                        [9.969, -36.335],
                        [5.639, -33.215],
                        [3.239, -35.375],
                        [3.959, -29.375],
                        [0.349, -11.145],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.929412007332, 0.780391991138, 0.77254897356, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Path',
              np: 2,
              cix: 2,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 4,
          nm: 'Path 21',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [4.499, 116.862, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-2.136, 3.269],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [6.032, 0.792],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-7.686, -1.008],
                      ],
                      v: [
                        [-0.363, -31.631],
                        [12.337, -49.501],
                        [28.637, -39.361],
                        [20.247, -22.941],
                        [8.429, -22.56],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.192157000303, 0.30980399251, 0.396077990532, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Path',
              np: 2,
              cix: 2,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
      ],
    },
    {
      id: 'comp_1',
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: 'Path 29',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  n: '0p833_0p833_0p167_0p167',
                  t: 0,
                  s: [50.997, 63.829, 0],
                  e: [39.497, 75.329, 0],
                  to: [-1.91666662693024, 1.91666662693024, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  n: '0p833_0p833_0p167_0p167',
                  t: 18,
                  s: [39.497, 75.329, 0],
                  e: [50.997, 63.829, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  n: '0p833_0p833_0p167_0p167',
                  t: 36,
                  s: [50.997, 63.829, 0],
                  e: [39.497, 75.329, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  n: '0p833_0p833_0p167_0p167',
                  t: 54,
                  s: [39.497, 75.329, 0],
                  e: [50.997, 63.829, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  n: '0p833_0p833_0p167_0p167',
                  t: 71.999,
                  s: [50.997, 63.829, 0],
                  e: [39.497, 75.329, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  n: '0p833_0p833_0p167_0p167',
                  t: 90,
                  s: [39.497, 75.329, 0],
                  e: [50.997, 63.829, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  n: '0p833_0p833_0p167_0p167',
                  t: 108,
                  s: [50.997, 63.829, 0],
                  e: [39.497, 75.329, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  n: '0p833_0p833_0p167_0p167',
                  t: 126,
                  s: [39.497, 75.329, 0],
                  e: [50.997, 63.829, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  n: '0p833_0p833_0p167_0p167',
                  t: 144,
                  s: [50.997, 63.829, 0],
                  e: [39.497, 75.329, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  n: '0p833_0p833_0p167_0p167',
                  t: 161.999,
                  s: [39.497, 75.329, 0],
                  e: [50.997, 63.829, 0],
                  to: [0, 0, 0],
                  ti: [-1.91666662693024, 1.91666662693024, 0],
                },
                { t: 180 },
              ],
              ix: 2,
            },
            a: { a: 0, k: [-35, -28.5, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [1.21, 2.23],
                        [0, 1.77],
                        [2.44, 0.67],
                        [0.8, -5.45],
                      ],
                      o: [
                        [-1.51, -2.77],
                        [0, -1.41],
                        [-4.27, 10.86],
                        [-0.81, 5.46],
                      ],
                      v: [
                        [-29.039, -27.172],
                        [-19.229, -62.772],
                        [-26.529, -66.562],
                        [-40.359, -27.172],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [0.78431372549, 1, 0.603921568627, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Path',
              np: 2,
              cix: 2,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 4,
          nm: 'Path 28',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  n: '0p833_0p833_0p167_0p167',
                  t: 0,
                  s: [120.497, 66.829, 0],
                  e: [132.997, 75.829, 0],
                  to: [2.08333325386047, 1.5, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  n: '0p833_0p833_0p167_0p167',
                  t: 18,
                  s: [132.997, 75.829, 0],
                  e: [120.497, 66.829, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  n: '0p833_0p833_0p167_0p167',
                  t: 36,
                  s: [120.497, 66.829, 0],
                  e: [132.997, 75.829, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  n: '0p833_0p833_0p167_0p167',
                  t: 54,
                  s: [132.997, 75.829, 0],
                  e: [120.497, 66.829, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  n: '0p833_0p833_0p167_0p167',
                  t: 71.999,
                  s: [120.497, 66.829, 0],
                  e: [132.997, 75.829, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  n: '0p833_0p833_0p167_0p167',
                  t: 90,
                  s: [132.997, 75.829, 0],
                  e: [120.497, 66.829, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  n: '0p833_0p833_0p167_0p167',
                  t: 108,
                  s: [120.497, 66.829, 0],
                  e: [132.997, 75.829, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  n: '0p833_0p833_0p167_0p167',
                  t: 126,
                  s: [132.997, 75.829, 0],
                  e: [120.497, 66.829, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  n: '0p833_0p833_0p167_0p167',
                  t: 144,
                  s: [120.497, 66.829, 0],
                  e: [132.997, 75.829, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  n: '0p833_0p833_0p167_0p167',
                  t: 161.999,
                  s: [132.997, 75.829, 0],
                  e: [120.497, 66.829, 0],
                  to: [0, 0, 0],
                  ti: [2.08333325386047, 1.5, 0],
                },
                { t: 180 },
              ],
              ix: 2,
            },
            a: { a: 0, k: [34.5, -25.5, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 2.13],
                        [1.01, 0.76],
                        [2.68, -1.51],
                        [0, -3.23],
                        [-2.606, 0.351],
                      ],
                      o: [
                        [0, -3.78],
                        [-0.8, -0.6],
                        [4.11, 11.45],
                        [0, 1.415],
                        [3.342, -0.45],
                      ],
                      v: [
                        [40.391, -27.172],
                        [25.041, -66.562],
                        [15.991, -62.772],
                        [28.311, -24.392],
                        [33.485, -20.959],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [0.78431372549, 1, 0.603921568627, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Path',
              np: 2,
              cix: 2,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 3,
          ty: 4,
          nm: 'Path 4',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  n: ['0p833_0p833_0p167_0p167'],
                  t: 0,
                  s: [0],
                  e: [-25],
                },
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  n: ['0p833_0p833_0p167_0p167'],
                  t: 18,
                  s: [-25],
                  e: [0],
                },
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  n: ['0p833_0p833_0p167_0p167'],
                  t: 36,
                  s: [0],
                  e: [-25],
                },
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  n: ['0p833_0p833_0p167_0p167'],
                  t: 54,
                  s: [-25],
                  e: [0],
                },
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  n: ['0p833_0p833_0p167_0p167'],
                  t: 71.999,
                  s: [0],
                  e: [-25],
                },
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  n: ['0p833_0p833_0p167_0p167'],
                  t: 90,
                  s: [-25],
                  e: [0],
                },
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  n: ['0p833_0p833_0p167_0p167'],
                  t: 108,
                  s: [0],
                  e: [-25],
                },
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  n: ['0p833_0p833_0p167_0p167'],
                  t: 126,
                  s: [-25],
                  e: [0],
                },
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  n: ['0p833_0p833_0p167_0p167'],
                  t: 144,
                  s: [0],
                  e: [-25],
                },
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  n: ['0p833_0p833_0p167_0p167'],
                  t: 161.999,
                  s: [-25],
                  e: [0],
                },
                { t: 180 },
              ],
              ix: 10,
            },
            p: { a: 0, k: [71.497, 98.829, 0], ix: 2 },
            a: { a: 0, k: [-14.5, 6.5, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.726, 1.112],
                        [1.21, 2.23],
                        [0.8, -5.45],
                        [-7.8, -11.36],
                        [-2.841, 2.891],
                      ],
                      o: [
                        [-6.79, -8.41],
                        [-1.51, -2.77],
                        [-0.81, 5.46],
                        [0, 0],
                        [2.931, -2.983],
                      ],
                      v: [
                        [-7.159, 0.858],
                        [-29.039, -27.172],
                        [-40.359, -27.172],
                        [-17.969, 13.738],
                        [-8.414, 12.008],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [0.78431372549, 1, 0.603921568627, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Path',
              np: 2,
              cix: 2,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 4,
          ty: 4,
          nm: 'Path 27',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  n: ['0p833_0p833_0p167_0p167'],
                  t: 0,
                  s: [0],
                  e: [25],
                },
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  n: ['0p833_0p833_0p167_0p167'],
                  t: 18,
                  s: [25],
                  e: [0],
                },
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  n: ['0p833_0p833_0p167_0p167'],
                  t: 36,
                  s: [0],
                  e: [25],
                },
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  n: ['0p833_0p833_0p167_0p167'],
                  t: 54,
                  s: [25],
                  e: [0],
                },
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  n: ['0p833_0p833_0p167_0p167'],
                  t: 71.999,
                  s: [0],
                  e: [25],
                },
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  n: ['0p833_0p833_0p167_0p167'],
                  t: 90,
                  s: [25],
                  e: [0],
                },
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  n: ['0p833_0p833_0p167_0p167'],
                  t: 108,
                  s: [0],
                  e: [25],
                },
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  n: ['0p833_0p833_0p167_0p167'],
                  t: 126,
                  s: [25],
                  e: [0],
                },
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  n: ['0p833_0p833_0p167_0p167'],
                  t: 144,
                  s: [0],
                  e: [25],
                },
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  n: ['0p833_0p833_0p167_0p167'],
                  t: 161.999,
                  s: [25],
                  e: [0],
                },
                { t: 180 },
              ],
              ix: 10,
            },
            p: { a: 0, k: [103.497, 98.829, 0], ix: 2 },
            a: { a: 0, k: [17.5, 6.5, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.942, 0.948],
                        [0, 3.79],
                        [0, -3.23],
                        [5.7, -7.07],
                        [-3.012, -3.991],
                      ],
                      o: [
                        [2.02, -2.02],
                        [0, -3.78],
                        [0, 3.23],
                        [0, 0],
                        [3.05, 4.041],
                      ],
                      v: [
                        [22.021, 13.738],
                        [40.391, -27.172],
                        [28.311, -24.392],
                        [11.211, 0.858],
                        [11.515, 9.413],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [0.78431372549, 1, 0.603921568627, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Path',
              np: 2,
              cix: 2,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 5,
          ty: 4,
          nm: 'Path 26',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  n: '0p833_0p833_0p167_0p167',
                  t: 0,
                  s: [67.736, 22.506, 0],
                  e: [55.236, 35.006, 0],
                  to: [-2.08333325386047, 2.08333325386047, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  n: '0p833_0p833_0p167_0p167',
                  t: 18,
                  s: [55.236, 35.006, 0],
                  e: [67.736, 22.506, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  n: '0p833_0p833_0p167_0p167',
                  t: 36,
                  s: [67.736, 22.506, 0],
                  e: [55.236, 35.006, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  n: '0p833_0p833_0p167_0p167',
                  t: 54,
                  s: [55.236, 35.006, 0],
                  e: [67.736, 22.506, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  n: '0p833_0p833_0p167_0p167',
                  t: 71.999,
                  s: [67.736, 22.506, 0],
                  e: [55.236, 35.006, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  n: '0p833_0p833_0p167_0p167',
                  t: 90,
                  s: [55.236, 35.006, 0],
                  e: [67.736, 22.506, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  n: '0p833_0p833_0p167_0p167',
                  t: 108,
                  s: [67.736, 22.506, 0],
                  e: [55.236, 35.006, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  n: '0p833_0p833_0p167_0p167',
                  t: 126,
                  s: [55.236, 35.006, 0],
                  e: [67.736, 22.506, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  n: '0p833_0p833_0p167_0p167',
                  t: 144,
                  s: [67.736, 22.506, 0],
                  e: [55.236, 35.006, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  n: '0p833_0p833_0p167_0p167',
                  t: 161.999,
                  s: [55.236, 35.006, 0],
                  e: [67.736, 22.506, 0],
                  to: [0, 0, 0],
                  ti: [-2.08333325386047, 2.08333325386047, 0],
                },
                { t: 180 },
              ],
              ix: 2,
            },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [2.28, 0],
                        [1.69, 1.1],
                        [0, -2.27],
                        [-1.27, 0],
                        [0, -1.76],
                      ],
                      o: [
                        [-1.83, 0],
                        [3.29, -9.33],
                        [0, 2.27],
                        [1.26, 0],
                        [0, 1.77],
                      ],
                      v: [
                        [0.508, 4.893],
                        [-6.842, 1.613],
                        [-0.001, -2.667],
                        [3.298, -0.907],
                        [6.839, -0.907],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.933333333333, 0.780392156863, 0.76862745098, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Path',
              np: 2,
              cix: 2,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 6,
          ty: 4,
          nm: 'Path 25',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  n: '0p833_0p833_0p167_0p167',
                  t: 0,
                  s: [103.085, 23.426, 0],
                  e: [115.585, 33.426, 0],
                  to: [2.08333325386047, 1.66666662693024, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  n: '0p833_0p833_0p167_0p167',
                  t: 18,
                  s: [115.585, 33.426, 0],
                  e: [103.085, 23.426, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  n: '0p833_0p833_0p167_0p167',
                  t: 36,
                  s: [103.085, 23.426, 0],
                  e: [115.585, 33.426, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  n: '0p833_0p833_0p167_0p167',
                  t: 54,
                  s: [115.585, 33.426, 0],
                  e: [103.085, 23.426, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  n: '0p833_0p833_0p167_0p167',
                  t: 71.999,
                  s: [103.085, 23.426, 0],
                  e: [115.585, 33.426, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  n: '0p833_0p833_0p167_0p167',
                  t: 90,
                  s: [115.585, 33.426, 0],
                  e: [103.085, 23.426, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  n: '0p833_0p833_0p167_0p167',
                  t: 108,
                  s: [103.085, 23.426, 0],
                  e: [115.585, 33.426, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  n: '0p833_0p833_0p167_0p167',
                  t: 126,
                  s: [115.585, 33.426, 0],
                  e: [103.085, 23.426, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  n: '0p833_0p833_0p167_0p167',
                  t: 144,
                  s: [103.085, 23.426, 0],
                  e: [115.585, 33.426, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  n: '0p833_0p833_0p167_0p167',
                  t: 161.999,
                  s: [115.585, 33.426, 0],
                  e: [103.085, 23.426, 0],
                  to: [0, 0, 0],
                  ti: [2.08333325386047, 1.66666662693024, 0],
                },
                { t: 180 },
              ],
              ix: 2,
            },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -2.52],
                        [-2.37, -0.25],
                        [0, 1.22],
                        [1.01, -1.26],
                        [0.51, 1.01],
                      ],
                      o: [
                        [0, 5.05],
                        [1.94, -0.58],
                        [0, -1.51],
                        [-1.01, 1.26],
                        [-0.5, -1.01],
                      ],
                      v: [
                        [-6.461, -3.087],
                        [0.639, 3.973],
                        [6.459, 0.693],
                        [4.179, -1.827],
                        [-2.411, -1.067],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.933333333333, 0.780392156863, 0.76862745098, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Path',
              np: 2,
              cix: 2,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 7,
          ty: 4,
          nm: 'Path 24',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [85.997, 92.329, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [1.43, 2.19],
                        [-3.028, -3.317],
                        [0, 0],
                        [-12.75, 5.13],
                        [0.664, 2.185],
                        [5.042, 3.063],
                      ],
                      o: [
                        [-6.24, 5.25],
                        [-3.838, -1.937],
                        [0, 0],
                        [7.04, 10.1],
                        [-1.93, -14.39],
                        [-1.768, -5.817],
                        [0, 0],
                      ],
                      v: [
                        [4.921, 0.858],
                        [-7.159, 0.858],
                        [-17.969, 13.738],
                        [-21.239, 61.718],
                        [25.801, 58.938],
                        [22.021, 13.738],
                        [11.211, 0.858],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [0.78431372549, 1, 0.603921568627, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Path',
              np: 2,
              cix: 2,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 8,
          ty: 4,
          nm: 'Path 23',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [105.687, 222.584, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [2.78, 2.27],
                        [12.46, -1.52],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, -5.86],
                        [-10.93, -20.88],
                        [0, 0],
                        [2.63, 6.47],
                      ],
                      o: [
                        [-2.22, -1.82],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [3.62, 11.54],
                        [0, 5.86],
                        [0, 0],
                        [-8.67, -19.12],
                        [-3.28, -8.09],
                      ],
                      v: [
                        [1.766, -69.041],
                        [-38.374, -66.771],
                        [-42.913, 69.669],
                        [-21.964, 69.669],
                        [-18.684, -42.261],
                        [-7.824, -0.321],
                        [24.986, 69.669],
                        [42.917, 59.059],
                        [13.626, -6.381],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.152941176471, 0.156862745098, 0.188235294118, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Path',
              np: 2,
              cix: 2,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 9,
          ty: 4,
          nm: 'Path 22',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [83.983, 81.857, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [-1.95, 5.72],
                        [-2.53, 0],
                        [-2.28, -2.78],
                        [1.77, -2.27],
                        [1.52, -2.1],
                        [0.41, -2.63],
                        [3.46, 1.09],
                      ],
                      o: [
                        [-10.75, -3.84],
                        [2.78, 1.26],
                        [2.54, 0],
                        [2.28, 2.77],
                        [-1.42, 1.81],
                        [0.84, 2.27],
                        [-0.4, 2.62],
                        [0, 0],
                      ],
                      v: [
                        [-3.321, 7.853],
                        [-10.921, -14.097],
                        [-0.791, -16.617],
                        [5.549, -9.047],
                        [11.879, -3.497],
                        [5.549, 5.073],
                        [7.569, 15.173],
                        [-3.321, 15.173],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.933333333333, 0.780392156863, 0.76862745098, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Path',
              np: 2,
              cix: 2,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 10,
          ty: 4,
          nm: 'Path 21',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [142.313, 294.795, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.5, -3.53],
                        [3.21, -1.26],
                        [-1.01, -0.2],
                        [0, 4.29],
                      ],
                      o: [
                        [-0.41, 2.82],
                        [-3.8, 7.57],
                        [1.27, 0.25],
                        [0, -4.29],
                      ],
                      v: [
                        [6.004, -14.252],
                        [-4.136, -6.942],
                        [-14.526, 15.258],
                        [14.614, -6.942],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.541175991881, 0.396077982585, 0.392156982422, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Path',
              np: 2,
              cix: 2,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 11,
          ty: 4,
          nm: 'Path 20',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [65.624, 299.841, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-11.15, 1.85],
                        [1.18, 1.35],
                        [3.04, 0.67],
                        [-1.41, -2.22],
                      ],
                      o: [
                        [0.81, -6.26],
                        [-2.03, 2.62],
                        [-7.61, 3.53],
                        [1.42, 2.22],
                      ],
                      v: [
                        [17.573, 4.163],
                        [15.033, -7.697],
                        [3.383, -6.437],
                        [-17.657, 6.933],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.541176021099, 0.396077990532, 0.392156988382, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Path',
              np: 2,
              cix: 2,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 12,
          ty: 4,
          nm: 'Path 19',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [92.056, 94.779, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [8.55, -10.09],
                        [14, 8.58],
                        [6.04, 9.08],
                        [-4.53, 5.05],
                        [0.67, 5.81],
                        [-9.66, 1.01],
                        [0, -5.55],
                        [-9.31, -12.12],
                        [1.51, -10.6],
                      ],
                      o: [
                        [-18.1, 17.77],
                        [-6.43, -3.53],
                        [-6.03, -9.09],
                        [3.62, -4.03],
                        [-1.67, -3.28],
                        [12.07, -1.27],
                        [0, 5.55],
                        [9.3, 12.11],
                        [-1.51, 10.6],
                      ],
                      v: [
                        [33.74, 39.075],
                        [-30.89, 35.545],
                        [-34.41, 18.135],
                        [-26.36, -6.355],
                        [-23.85, -28.815],
                        [-11.61, -47.885],
                        [10.1, -36.385],
                        [22.68, -9.125],
                        [28.46, 13.585],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.294117647059, 0.325490196078, 0.380392156863, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Path',
              np: 2,
              cix: 2,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
      ],
    },
    {
      id: 'comp_2',
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: 'Oval 3',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [167, 192.5, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  d: 1,
                  ty: 'el',
                  s: { a: 0, k: [45, 45], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  nm: 'Ellipse Path 1',
                  mn: 'ADBE Vector Shape - Ellipse',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Oval',
              np: 2,
              cix: 2,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 4,
          nm: 'Oval 2',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [167, 192.5, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  d: 1,
                  ty: 'el',
                  s: { a: 0, k: [84, 84], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  nm: 'Ellipse Path 1',
                  mn: 'ADBE Vector Shape - Ellipse',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.933333333333, 0.494117647059, 0.474509803922, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Oval',
              np: 2,
              cix: 2,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 3,
          ty: 4,
          nm: 'Path 26',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [165.641, 85.625, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [-17.07, 8.08],
                        [-11.51, -26.12],
                      ],
                      o: [
                        [15.4, -34.37],
                        [25.35, 14.2],
                        [0, 0],
                      ],
                      v: [
                        [-54.159, 31.125],
                        [-0.139, -31.125],
                        [54.161, 31.125],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.933333333333, 0.494117647059, 0.474509803922, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Path',
              np: 2,
              cix: 2,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 4,
          ty: 4,
          nm: 'Path 25',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [165.397, 228.5, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [-3.93, 40.1],
                        [-8.71, 19.42],
                        [0, 0],
                        [-2.49, -25.82],
                        [9.77, -42.09],
                      ],
                      o: [
                        [-15.59, -60.84],
                        [2.87, -29.25],
                        [0, 0],
                        [9.68, 21.98],
                        [5.07, 52.53],
                        [0, 0],
                      ],
                      v: [
                        [-57.211, 111.75],
                        [-72.231, -39],
                        [-53.911, -111.75],
                        [54.409, -111.75],
                        [72.079, -39],
                        [60.029, 111.75],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Path',
              np: 2,
              cix: 2,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 5,
          ty: 4,
          nm: 'Path 24',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [166.806, 353, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [2.27, -7.7],
                        [0, 0],
                        [2.12, 8.29],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [-2.59, -8.71],
                        [0, 0],
                        [-2.14, 9.24],
                      ],
                      v: [
                        [51.948, 12.75],
                        [-51.552, 12.75],
                        [-58.622, -12.75],
                        [58.618, -12.75],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.02352900058, 0.027450999245, 0.050980001688, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Path',
              np: 2,
              cix: 2,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 6,
          ty: 4,
          nm: 'Path 23',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [63.193, 325.279, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [25.2, -1.8],
                        [-2.75, -18.75],
                        [0, 0],
                      ],
                      o: [
                        [-25.2, 1.8],
                        [0, 0],
                        [2, -20.25],
                      ],
                      v: [
                        [-0.863, -29.219],
                        [-24.113, 29.281],
                        [24.637, 29.281],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.933333333333, 0.494117647059, 0.474509803922, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Path',
              np: 2,
              cix: 2,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 7,
          ty: 4,
          nm: 'Path 22',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [269.862, 324.471, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [25.2, -1.8],
                        [-2.75, -18.75],
                        [0, 0],
                      ],
                      o: [
                        [-25.2, 1.8],
                        [0, 0],
                        [2, -20.25],
                      ],
                      v: [
                        [-0.863, -29.219],
                        [-24.113, 29.281],
                        [24.637, 29.281],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.933333333333, 0.494117647059, 0.474509803922, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Path',
              np: 2,
              cix: 2,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 8,
          ty: 4,
          nm: 'Path 20',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [167.229, 376.5, 0], ix: 2 },
            a: { a: 0, k: [0, -41, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  n: [
                    '0p833_0p833_0p167_0p167',
                    '0p833_0p833_0p167_0p167',
                    '0p833_0p833_0p167_0p167',
                  ],
                  t: 0,
                  s: [100, 100, 100],
                  e: [100, 125, 100],
                },
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  n: [
                    '0p833_0p833_0p167_0p167',
                    '0p833_0p833_0p167_0p167',
                    '0p833_0p833_0p167_0p167',
                  ],
                  t: 30,
                  s: [100, 125, 100],
                  e: [100, 83, 100],
                },
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  n: [
                    '0p833_0p833_0p167_0p167',
                    '0p833_0p833_0p167_0p167',
                    '0p833_0p833_0p167_0p167',
                  ],
                  t: 60,
                  s: [100, 83, 100],
                  e: [100, 122.25, 100],
                },
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  n: [
                    '0p833_0p833_0p167_0p167',
                    '0p833_0p833_0p167_0p167',
                    '0p833_0p833_0p167_0p167',
                  ],
                  t: 90,
                  s: [100, 122.25, 100],
                  e: [100, 93.833, 100],
                },
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  n: [
                    '0p833_0p833_0p167_0p167',
                    '0p833_0p833_0p167_0p167',
                    '0p833_0p833_0p167_0p167',
                  ],
                  t: 120,
                  s: [100, 93.833, 100],
                  e: [100, 124.917, 100],
                },
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  n: [
                    '0p833_0p833_0p167_0p167',
                    '0p833_0p833_0p167_0p167',
                    '0p833_0p833_0p167_0p167',
                  ],
                  t: 150,
                  s: [100, 124.917, 100],
                  e: [100, 100, 100],
                },
                { t: 180 },
              ],
              ix: 6,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [40.8, 6.6],
                        [-39.6, -63.6],
                      ],
                      o: [
                        [-16.5, 1.5],
                        [17, -25.25],
                      ],
                      v: [
                        [-0.327, -42],
                        [-0.327, 42],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.736550245098, 0.447794117647, 0.052787990196, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Path',
              np: 2,
              cix: 2,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 9,
          ty: 4,
          nm: 'Path 19',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [167.065, 441.875, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  n: [
                    '0p833_0p833_0p167_0p167',
                    '0p833_0p833_0p167_0p167',
                    '0p833_0p833_0p167_0p167',
                  ],
                  t: 0,
                  s: [100, 100, 100],
                  e: [121, 100, 100],
                },
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  n: [
                    '0p833_0p833_0p167_0p167',
                    '0p833_0p833_0p167_0p167',
                    '0p833_0p833_0p167_0p167',
                  ],
                  t: 30,
                  s: [121, 100, 100],
                  e: [84, 100, 100],
                },
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  n: [
                    '0p833_0p833_0p167_0p167',
                    '0p833_0p833_0p167_0p167',
                    '0p833_0p833_0p167_0p167',
                  ],
                  t: 60,
                  s: [84, 100, 100],
                  e: [120, 100, 100],
                },
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  n: [
                    '0p833_0p833_0p167_0p167',
                    '0p833_0p833_0p167_0p167',
                    '0p833_0p833_0p167_0p167',
                  ],
                  t: 90,
                  s: [120, 100, 100],
                  e: [98.333, 100, 100],
                },
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  n: [
                    '0p833_0p833_0p167_0p167',
                    '0p833_0p833_0p167_0p167',
                    '0p833_0p833_0p167_0p167',
                  ],
                  t: 120,
                  s: [98.333, 100, 100],
                  e: [122.167, 100, 100],
                },
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  n: [
                    '0p833_0p833_0p167_0p167',
                    '0p833_0p833_0p167_0p167',
                    '0p833_0p833_0p167_0p167',
                  ],
                  t: 150,
                  s: [122.167, 100, 100],
                  e: [100, 100, 100],
                },
                { t: 180 },
              ],
              ix: 6,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [79.2, 7.8],
                        [-78.6, -102],
                      ],
                      o: [
                        [-32.75, 1.75],
                        [33, -41],
                      ],
                      v: [
                        [-0.165, -66.375],
                        [-0.165, 66.375],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 0.925490196078, 0.003921568627, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Path',
              np: 2,
              cix: 2,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 10,
          ty: 4,
          nm: 'Path 21',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [167.166, 250.17, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [54.11, 43.94],
                        [0, 0],
                        [59.43, -52.95],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-54.12, -43.93],
                        [0, 0],
                        [-59.43, 52.94],
                        [0, 0],
                      ],
                      v: [
                        [-160.666, 90.077],
                        [160.664, 90.127],
                        [160.664, 42.827],
                        [104.214, -35.173],
                        [0.064, -90.103],
                        [-101.236, -38.233],
                        [-160.666, 42.827],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [0.133, 0.65, 0.7, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Path 5',
              np: 2,
              cix: 2,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
      ],
    },
    {
      id: 'comp_3',
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: 'Path',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  n: '0p833_0p833_0p167_0p167',
                  t: 0,
                  s: [948.17, 300.108, 0],
                  e: [1077.17, 346.108, 0],
                  to: [118.5, -47.3333320617676, 0],
                  ti: [10.2688608169556, -44.6345710754395, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  n: '0p833_0p833_0p167_0p167',
                  t: 45,
                  s: [1077.17, 346.108, 0],
                  e: [1249.17, 884.108, 0],
                  to: [-74.2556762695312, 322.75927734375, 0],
                  ti: [-7.66666650772095, -139.33332824707, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  n: '0p833_0p833_0p167_0p167',
                  t: 106,
                  s: [1249.17, 884.108, 0],
                  e: [1123.17, 1182.108, 0],
                  to: [7.66666650772095, 139.33332824707, 0],
                  ti: [0, 0, 0],
                },
                { t: 179 },
              ],
              ix: 2,
            },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-4.951, 9.818],
                        [11.609, 3.828],
                        [0.819, -9.822],
                        [-11.611, 1.118],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 0.796078431373, 0.337254901961, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Path',
              np: 2,
              cix: 2,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 4,
          nm: 'Path 18',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  n: '0p833_0p833_0p167_0p167',
                  t: 0,
                  s: [940.75, 308.625, 0],
                  e: [765.75, 318.625, 0],
                  to: [-35.6666679382324, -105.333335876465, 0],
                  ti: [7.9813027381897, -169.310913085938, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  n: '0p833_0p833_0p167_0p167',
                  t: 45,
                  s: [765.75, 318.625, 0],
                  e: [689.692, 788.433, 0],
                  to: [-13.2903537750244, 281.934173583984, 0],
                  ti: [-5.05010175704956, -205.983413696289, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  n: '0p833_0p833_0p167_0p167',
                  t: 106,
                  s: [689.692, 788.433, 0],
                  e: [751.75, 1277.625, 0],
                  to: [6.64294528961182, 270.952301025391, 0],
                  ti: [0, 0, 0],
                },
                { t: 179 },
              ],
              ix: 2,
            },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-3.75, 10.125],
                        [-10.5, -7.875],
                        [4.5, -13.875],
                        [10.5, 13.875],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 0.796078431373, 0.337254901961, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Path',
              np: 2,
              cix: 2,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 3,
          ty: 4,
          nm: 'Path 17',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  n: '0p833_0p833_0p167_0p167',
                  t: 0,
                  s: [950.875, 353.625, 0],
                  e: [1174.875, 238.625, 0],
                  to: [2.33333325386047, -103.833335876465, 0],
                  ti: [-55.7662620544434, -64.9507217407227, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  n: '0p833_0p833_0p167_0p167',
                  t: 45,
                  s: [1174.875, 238.625, 0],
                  e: [1234.303, 634.152, 0],
                  to: [73.0201110839844, 85.0462036132812, 0],
                  ti: [-71.5893936157227, -180.220397949219, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  n: '0p833_0p833_0p167_0p167',
                  t: 106,
                  s: [1234.303, 634.152, 0],
                  e: [1244.875, 1131.625, 0],
                  to: [82.6001358032227, 207.939041137695, 0],
                  ti: [0, 0, 0],
                },
                { t: 179 },
              ],
              ix: 2,
            },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-13.875, 8.625],
                        [-4.125, -8.625],
                        [13.875, -8.625],
                        [6.375, 8.625],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 0.796078431373, 0.337254901961, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Path',
              np: 2,
              cix: 2,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 4,
          ty: 4,
          nm: 'Path 16',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  n: '0p833_0p833_0p167_0p167',
                  t: 0,
                  s: [931.25, 303, 0],
                  e: [730.25, 384, 0],
                  to: [-58.3333320617676, -59.3333320617676, 0],
                  ti: [15.5815134048462, -132.888198852539, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  n: '0p833_0p833_0p167_0p167',
                  t: 45,
                  s: [730.25, 384, 0],
                  e: [809.935, 859.107, 0],
                  to: [-24.9164524078369, 212.501983642578, 0],
                  ti: [3.95723152160645, -203.670959472656, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  n: '0p833_0p833_0p167_0p167',
                  t: 106,
                  s: [809.935, 859.107, 0],
                  e: [717.25, 1344, 0],
                  to: [-4.68123483657837, 240.933990478516, 0],
                  ti: [0, 0, 0],
                },
                { t: 179 },
              ],
              ix: 2,
            },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-7.5, 12.75],
                        [-14.25, -3],
                        [9, -12.75],
                        [14.25, 2.25],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 0.796078431373, 0.337254901961, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Path',
              np: 2,
              cix: 2,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 5,
          ty: 4,
          nm: 'Path 15',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  n: '0p833_0p833_0p167_0p167',
                  t: 0,
                  s: [940.89, 322.369, 0],
                  e: [1230.89, 337.369, 0],
                  to: [73.3333358764648, -121.833335876465, 0],
                  ti: [19.7401962280273, -141.634963989258, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  n: '0p833_0p833_0p167_0p167',
                  t: 45,
                  s: [1230.89, 337.369, 0],
                  e: [1347.739, 724.754, 0],
                  to: [-11.9246520996094, 85.5588150024414, 0],
                  ti: [-95.6911239624023, -187.679306030273, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  n: '0p833_0p833_0p167_0p167',
                  t: 106,
                  s: [1347.739, 724.754, 0],
                  e: [1300.89, 1229.369, 0],
                  to: [119.159614562988, 233.708114624023, 0],
                  ti: [0, 0, 0],
                },
                { t: 179 },
              ],
              ix: 2,
            },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [9, 0.6],
                        [1, 7.25],
                        [0, -3.6],
                        [-1.5, -3.75],
                        [-3.6, 0.6],
                        [-9, -8.25],
                        [0.75, -6.75],
                        [0, 0],
                        [3.75, 4.75],
                        [6.299, -3.913],
                        [1.774, -1.311],
                      ],
                      o: [
                        [-9, -0.6],
                        [5.25, -1.5],
                        [0, 3.6],
                        [6.25, -4],
                        [4.5, -0.75],
                        [7.2, 6.6],
                        [0, 0],
                        [0, -7.2],
                        [-4.979, 0],
                        [-2.81, 1.745],
                        [-2.75, 0.25],
                      ],
                      v: [
                        [-27.14, 5.121],
                        [-41.39, -17.379],
                        [-25.64, -17.379],
                        [-21.14, -1.629],
                        [2.11, -14.379],
                        [34.36, -9.129],
                        [41.11, 19.371],
                        [29.86, 15.621],
                        [18.61, -7.629],
                        [-0.565, 0.35],
                        [-7.64, 5.121],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 0.796078431373, 0.337254901961, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Path',
              np: 2,
              cix: 2,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 6,
          ty: 4,
          nm: 'Path 14',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  n: '0p833_0p833_0p167_0p167',
                  t: 0,
                  s: [949.125, 339.125, 0],
                  e: [810.125, 212.125, 0],
                  to: [-6, -120.666664123535, 0],
                  ti: [87, -41.3333320617676, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  n: '0p833_0p833_0p167_0p167',
                  t: 45,
                  s: [810.125, 212.125, 0],
                  e: [654.678, 601.096, 0],
                  to: [-15.7054758071899, 7.46160507202148, 0],
                  ti: [-113.89599609375, -175.464385986328, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  n: '0p833_0p833_0p167_0p167',
                  t: 106,
                  s: [654.678, 601.096, 0],
                  e: [797.125, 1171.125, 0],
                  to: [182.487686157227, 281.134460449219, 0],
                  ti: [0, 0, 0],
                },
                { t: 179 },
              ],
              ix: 2,
            },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [2.25, 5.5],
                        [6, -9],
                        [2.25, -11],
                        [-5, -1.25],
                        [0.75, 12.75],
                      ],
                      o: [
                        [-2.4, -1.2],
                        [-12, 3.75],
                        [-4.8, 7.2],
                        [3, -0.6],
                        [5.4, -3.6],
                        [0, 0],
                      ],
                      v: [
                        [27.375, -19.125],
                        [17.625, -37.125],
                        [-14.625, -7.875],
                        [-27.375, 34.125],
                        [-8.625, 37.125],
                        [-4.125, -5.625],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 0.796078431373, 0.337254901961, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Path',
              np: 2,
              cix: 2,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 7,
          ty: 4,
          nm: 'Path 13',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  n: '0p833_0p833_0p167_0p167',
                  t: 0,
                  s: [948.25, 363.75, 0],
                  e: [1166.25, 399.75, 0],
                  to: [102.833335876465, -122.333335876465, 0],
                  ti: [-6.07192802429199, -86.9057159423828, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  n: '0p833_0p833_0p167_0p167',
                  t: 45,
                  s: [1166.25, 399.75, 0],
                  e: [1221.715, 841.857, 0],
                  to: [8.5975399017334, 123.054061889648, 0],
                  ti: [47.3162612915039, -184.682800292969, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  n: '0p833_0p833_0p167_0p167',
                  t: 106,
                  s: [1221.715, 841.857, 0],
                  e: [1307.25, 1285.75, 0],
                  to: [-71.4641265869141, 278.935729980469, 0],
                  ti: [0, 0, 0],
                },
                { t: 179 },
              ],
              ix: 2,
            },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [1.88, -4.93],
                        [5.25, 3.83],
                        [-2.25, 6.75],
                        [-5.25, -6.75],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 0.796078431373, 0.337254901961, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Path',
              np: 2,
              cix: 2,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 8,
          ty: 4,
          nm: 'Path 12',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  n: '0p833_0p833_0p167_0p167',
                  t: 0,
                  s: [954.5, 339.5, 0],
                  e: [1146.5, 451.5, 0],
                  to: [139.16667175293, -69.6666641235352, 0],
                  ti: [15.5710983276367, -85.9535522460938, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  n: '0p833_0p833_0p167_0p167',
                  t: 45,
                  s: [1146.5, 451.5, 0],
                  e: [1094.755, 861.245, 0],
                  to: [-24.2963619232178, 134.117614746094, 0],
                  ti: [5.39423179626465, -150.769454956055, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  n: '0p833_0p833_0p167_0p167',
                  t: 106,
                  s: [1094.755, 861.245, 0],
                  e: [1177.5, 1177.5, 0],
                  to: [-6.38916301727295, 178.577911376953, 0],
                  ti: [0, 0, 0],
                },
                { t: 179 },
              ],
              ix: 2,
            },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [-1.11, -2.67],
                        [-2.96, 4.36],
                        [-1.11, 5.33],
                        [2.47, 0.61],
                        [-0.36, -6.18],
                      ],
                      o: [
                        [1.18, 0.58],
                        [5.92, -1.82],
                        [2.37, -3.49],
                        [-1.48, 0.29],
                        [-2.66, 1.75],
                        [0, 0],
                      ],
                      v: [
                        [-13.5, 9.27],
                        [-8.69, 18],
                        [7.21, 3.82],
                        [13.5, -16.54],
                        [4.25, -18],
                        [2.03, 2.73],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 0.796078431373, 0.337254901961, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Path',
              np: 2,
              cix: 2,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 9,
          ty: 4,
          nm: 'Path 11',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  n: '0p833_0p833_0p167_0p167',
                  t: 0,
                  s: [939.936, 317.652, 0],
                  e: [657.936, 266.652, 0],
                  to: [-110.833335876465, -196.83332824707, 0],
                  ti: [-66.2000198364258, -102.05037689209, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  n: '0p833_0p833_0p167_0p167',
                  t: 45,
                  s: [657.936, 266.652, 0],
                  e: [574.584, 708.53, 0],
                  to: [85.3139190673828, 131.515319824219, 0],
                  ti: [90.9188919067383, -138.065185546875, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  n: '0p833_0p833_0p167_0p167',
                  t: 106,
                  s: [574.584, 708.53, 0],
                  e: [644.936, 1226.652, 0],
                  to: [-145.589324951172, 221.085144042969, 0],
                  ti: [0, 0, 0],
                },
                { t: 179 },
              ],
              ix: 2,
            },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [7.25, -4.5],
                        [0, 0],
                        [-7.5, -0.5],
                        [2, -8.5],
                        [-3.5, -2.25],
                        [14.25, 9],
                      ],
                      o: [
                        [0, 0],
                        [2.4, -3.6],
                        [2.4, 4.8],
                        [3, 0],
                        [3, -3],
                        [-11.4, -7.2],
                      ],
                      v: [
                        [-22.936, -19.102],
                        [-16.936, -8.602],
                        [8.564, -11.602],
                        [5.564, 19.898],
                        [19.814, 26.648],
                        [13.064, -23.602],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 0.796078431373, 0.337254901961, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Path',
              np: 2,
              cix: 2,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 10,
          ty: 4,
          nm: 'Path 10',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  n: '0p833_0p833_0p167_0p167',
                  t: 0,
                  s: [946.125, 338, 0],
                  e: [1120.125, 222, 0],
                  to: [29, -19.3333339691162, 0],
                  ti: [-2.22080636024475, -123.650764465332, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  n: '0p833_0p833_0p167_0p167',
                  t: 45,
                  s: [1120.125, 222, 0],
                  e: [1228.925, 645.256, 0],
                  to: [1.86954915523529, 104.093353271484, 0],
                  ti: [57.1274108886719, -157.971862792969, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  n: '0p833_0p833_0p167_0p167',
                  t: 106,
                  s: [1228.925, 645.256, 0],
                  e: [1190.125, 1114, 0],
                  to: [-92.5926513671875, 256.042297363281, 0],
                  ti: [-5.63869047164917, -74.360237121582, 0],
                },
                { t: 179 },
              ],
              ix: 2,
            },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-7.875, 9],
                        [-16.125, -6.75],
                        [16.125, -9],
                        [9.375, 6],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 0.796078431373, 0.337254901961, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Path',
              np: 2,
              cix: 2,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 11,
          ty: 4,
          nm: 'Path 9',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  n: '0p833_0p833_0p167_0p167',
                  t: 0,
                  s: [945.75, 327.5, 0],
                  e: [729.75, 459.5, 0],
                  to: [-36, 22, 0],
                  ti: [15.9822511672974, -125.859870910645, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  n: '0p833_0p833_0p167_0p167',
                  t: 45,
                  s: [729.75, 459.5, 0],
                  e: [641.541, 791.005, 0],
                  to: [-20.8538646697998, 164.223709106445, 0],
                  ti: [15.7144908905029, -157.229370117188, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  n: '0p833_0p833_0p167_0p167',
                  t: 106,
                  s: [641.541, 791.005, 0],
                  e: [737.75, 1108.5, 0],
                  to: [-18.8931789398193, 189.033340454102, 0],
                  ti: [-0.18250241875648, -54.7507247924805, 0],
                },
                { t: 179 },
              ],
              ix: 2,
            },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-11.25, 9.75],
                        [-11.25, -14.25],
                        [11.25, -6.75],
                        [3, 14.25],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 0.796078431373, 0.337254901961, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Path',
              np: 2,
              cix: 2,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 12,
          ty: 4,
          nm: 'Path 8',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  n: '0p833_0p833_0p167_0p167',
                  t: 0,
                  s: [942.839, 322.563, 0],
                  e: [1164.839, 284.563, 0],
                  to: [37, -6.33333349227905, 0],
                  ti: [-33.5282096862793, -134.246444702148, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  n: '0p833_0p833_0p167_0p167',
                  t: 45,
                  s: [1164.839, 284.563, 0],
                  e: [1319.426, 733.494, 0],
                  to: [37.3477401733398, 149.539794921875, 0],
                  ti: [80.2878494262695, -143.720916748047, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  n: '0p833_0p833_0p167_0p167',
                  t: 106,
                  s: [1319.426, 733.494, 0],
                  e: [1233.839, 1176.563, 0],
                  to: [-97.3067474365234, 174.185958862305, 0],
                  ti: [-5.69375371932983, -75.0863723754883, 0],
                },
                { t: 179 },
              ],
              ix: 2,
            },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-4.171, 5.767],
                        [9.928, 5.927],
                        [10.318, -5.923],
                        [-10.322, -3.133],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 0.796078431373, 0.337254901961, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Path',
              np: 2,
              cix: 2,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 13,
          ty: 4,
          nm: 'Path 7',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  n: '0p833_0p833_0p167_0p167',
                  t: 0,
                  s: [957.354, 348.433, 0],
                  e: [1128.354, 321.433, 0],
                  to: [28.5, -4.5, 0],
                  ti: [-12.2764949798584, -137.983932495117, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  n: '0p833_0p833_0p167_0p167',
                  t: 45,
                  s: [1128.354, 321.433, 0],
                  e: [1282.584, 756.779, 0],
                  to: [11.4443817138672, 128.631240844727, 0],
                  ti: [-4.15997362136841, -135.90266418457, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  n: '0p833_0p833_0p167_0p167',
                  t: 106,
                  s: [1282.584, 756.779, 0],
                  e: [1198.354, 1217.433, 0],
                  to: [5.76537084579468, 188.349578857422, 0],
                  ti: [-5.69962930679321, -75.1638641357422, 0],
                },
                { t: 179 },
              ],
              ix: 2,
            },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-6.735, 8.512],
                        [5.815, 9.242],
                        [6.735, -9.238],
                        [-4.925, -9.108],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 0.796078431373, 0.337254901961, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Path',
              np: 2,
              cix: 2,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 14,
          ty: 4,
          nm: 'Path 6',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  n: '0p833_0p833_0p167_0p167',
                  t: 0,
                  s: [951.17, 335.108, 0],
                  e: [1083.17, 394.108, 0],
                  to: [22, 9.83333301544189, 0],
                  ti: [-9.94303512573242, -142.149948120117, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  n: '0p833_0p833_0p167_0p167',
                  t: 45,
                  s: [1083.17, 394.108, 0],
                  e: [1174.28, 749.792, 0],
                  to: [7.90503978729248, 113.013885498047, 0],
                  ti: [55.7971000671387, -153.167282104492, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  n: '0p833_0p833_0p167_0p167',
                  t: 106,
                  s: [1174.28, 749.792, 0],
                  e: [1147.17, 1238.108, 0],
                  to: [-87.4352798461914, 240.016494750977, 0],
                  ti: [-5.71911764144897, -75.4208602905273, 0],
                },
                { t: 179 },
              ],
              ix: 2,
            },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-4.951, 9.818],
                        [11.609, 3.828],
                        [0.819, -9.822],
                        [-11.611, 1.118],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 0.796078431373, 0.337254901961, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Path',
              np: 2,
              cix: 2,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
      ],
    },
  ],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [686.043, 758.642, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-4.89, 9.91],
                    [2.73, 1.28],
                    [2.89, -3.6],
                    [-0.96, -0.24],
                    [-0.97, 2.64],
                    [0, -2.16],
                    [-0.72, 2.4],
                    [0.48, 1.2],
                    [-0.97, 9.4],
                  ],
                  o: [
                    [-0.96, -3.27],
                    [-9.38, 5.52],
                    [-2.89, 3.6],
                    [0.96, 0.24],
                    [0.96, -2.64],
                    [0, 2.16],
                    [0.57, -1.92],
                    [0.8, -2.16],
                    [0.96, -9.41],
                  ],
                  v: [
                    [15.54, -30.365],
                    [6.15, -38.285],
                    [-11.66, 26.975],
                    [-15.27, 38.015],
                    [-9.97, 36.335],
                    [-5.64, 33.215],
                    [-3.23, 35.375],
                    [-3.95, 29.375],
                    [-0.34, 11.145],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.929412007332, 0.780391991138, 0.77254897356, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 2,
      ty: 0,
      nm: 'arm',
      refId: 'comp_0',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              n: ['0p667_1_0p333_0'],
              t: 0,
              s: [0],
              e: [16],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              n: ['0p667_1_0p333_0'],
              t: 30,
              s: [16],
              e: [-9.2],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              n: ['0p667_1_0p333_0'],
              t: 60,
              s: [-9.2],
              e: [16],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              n: ['0p667_1_0p333_0'],
              t: 90,
              s: [16],
              e: [-9.2],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              n: ['0p667_1_0p333_0'],
              t: 120,
              s: [-9.2],
              e: [16],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              n: ['0p667_1_0p333_0'],
              t: 150,
              s: [16],
              e: [0],
            },
            { t: 180 },
          ],
          ix: 10,
        },
        p: { a: 0, k: [742, 686.5, 0], ix: 2 },
        a: { a: 0, k: [14, 91.5, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 50,
      h: 98,
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 3,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [715.825, 674.441, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-2.89, 0],
                    [-1.44, -2.32],
                    [0, -4.07],
                    [-1.04, -0.64],
                    [6.49, 1.36],
                    [0.77, 3.07],
                    [3.12, 0],
                    [0.96, 2.15],
                    [-0.97, 0.24],
                    [0.77, 3.26],
                    [-3.37, 0],
                  ],
                  o: [
                    [2.31, 0],
                    [2.23, 3.12],
                    [0, 3.27],
                    [5.59, 12.29],
                    [0, -1.6],
                    [-0.96, -3.84],
                    [-3.13, 0],
                    [-0.77, -1.73],
                    [0.96, 0.16],
                    [-0.96, -4.08],
                    [3.37, 0],
                  ],
                  v: [
                    [2.606, -19.826],
                    [9.826, -12.866],
                    [9.826, 2.004],
                    [12.956, 8.004],
                    [0.446, 19.284],
                    [-0.524, 10.644],
                    [-6.054, 4.644],
                    [-14.244, -0.866],
                    [-12.314, -3.746],
                    [-10.394, -7.346],
                    [-4.614, -12.866],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.929412007332, 0.780391991138, 0.77254897356, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 4,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [732.499, 711.862, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [2.8, 5.39],
                    [-11.9, 8.29],
                    [-5.553, -2.374],
                    [0, 0],
                    [0, 0],
                    [13.74, 0.24],
                    [0, 0],
                    [1.92, 0],
                  ],
                  o: [
                    [5.37, -10.82],
                    [0, 0],
                    [5.577, 2.383],
                    [0, 0],
                    [-4.41, 17.38],
                    [0, 0],
                    [-4.47, 3.62],
                    [-1.91, 0],
                  ],
                  v: [
                    [-42.773, 6.759],
                    [-0.363, -31.631],
                    [11.055, -31.488],
                    [20.247, -22.941],
                    [42.777, 28.489],
                    [-3.953, 49.489],
                    [-16.173, 12.069],
                    [-31.993, 22.929],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.192157000303, 0.30980399251, 0.396077990532, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 5,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [754.604, 811.346, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 10.33],
                    [8.33, 19.92],
                    [10.73, -5.5],
                    [0, -5.36],
                    [9.69, -15.69],
                    [0, 0],
                    [-6.73, 6.88],
                    [-0.08, 8.44],
                    [-3.6, -25.89],
                    [0, 0],
                  ],
                  o: [
                    [0, -10.33],
                    [-10.77, 0],
                    [1.6, 12.91],
                    [0, 5.35],
                    [0, 0],
                    [8.33, -16.73],
                    [6.73, -6.88],
                    [11.73, 14.53],
                    [0, 0],
                    [-3.28, -15.3],
                  ],
                  v: [
                    [42.536, 5.378],
                    [17.546, -67.283],
                    [-28.115, -50.792],
                    [-23.305, -5.372],
                    [-52.385, 48.398],
                    [-36.525, 64.178],
                    [-3.124, 5.378],
                    [5.525, -28.562],
                    [30.995, 67.277],
                    [52.385, 64.178],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [
                  0.2901960784313726,
                  0.5647058823529412,
                  0.8862745098039215,
                  1,
                ],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 6,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [710.245, 882.953, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-2.08, 1.19],
                    [-2.24, 0.24],
                    [0, 0],
                    [12.24, 1.42],
                    [0, 2.61],
                  ],
                  o: [
                    [1.54, 3.99],
                    [0, 0],
                    [0, 4.51],
                    [-12.25, -1.43],
                    [0, -2.09],
                  ],
                  v: [
                    [-7.552, -13.937],
                    [1.088, -9.667],
                    [1.088, -1.127],
                    [9.978, 13.823],
                    [-13.792, -7.767],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.294117987156, 0.325489997864, 0.380391985178, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 7,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [809.538, 887.52, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.8, 3.79],
                    [-0.16, 1.26],
                    [-1.16, 0],
                    [1.68, -3.55],
                    [2.41, 1.66],
                    [0.32, -1.1],
                    [0, 0],
                  ],
                  o: [
                    [6.53, 0],
                    [1.12, 2.45],
                    [1.44, 0],
                    [-1.68, 3.56],
                    [-1.92, -1.33],
                    [0, 0],
                    [-1.92, 0],
                  ],
                  v: [
                    [-14.653, -3.792],
                    [-6.013, -7.592],
                    [-1.203, -0.242],
                    [15.597, -0.242],
                    [-2.653, 5.928],
                    [-6.013, 7.589],
                    [-14.653, 7.589],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.294117987156, 0.325489997864, 0.380391985178, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 8,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [707.025, 665.244, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-14.44, -0.94],
                    [-2.77, 7.88],
                    [3.43, -1.42],
                    [5.38, -13.23],
                  ],
                  o: [
                    [11.56, 0.76],
                    [-8.32, -7.09],
                    [-3.43, 1.42],
                    [-5.39, 13.23],
                  ],
                  v: [
                    [-4.502, 20.155],
                    [18.258, -2.525],
                    [12.878, -19.295],
                    [-15.512, -12.205],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.282353013754, 0.019608000293, 0.164706006646, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 9,
      ty: 0,
      nm: 'gi',
      refId: 'comp_1',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [1071.5, 592, 0], ix: 2 },
        a: { a: 0, k: [97, 165, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 194,
      h: 330,
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 10,
      ty: 0,
      nm: 'rock',
      refId: 'comp_2',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 0,
              s: [954, 386, 0],
              e: [954, 360, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 45,
              s: [954, 360, 0],
              e: [954, 393.667, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 90,
              s: [954, 393.667, 0],
              e: [954, 352.837, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 135,
              s: [954, 352.837, 0],
              e: [954, 386, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            { t: 180 },
          ],
          ix: 2,
        },
        a: { a: 0, k: [167, 272.5, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 334,
      h: 545,
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 11,
      ty: 0,
      nm: 'confeti',
      refId: 'comp_3',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [960, 540, 0], ix: 2 },
        a: { a: 0, k: [960, 540, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 1920,
      h: 1080,
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 12,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [957.75, 729.75, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [-10.3, -3.18],
                    [-12.84, 7.63],
                    [-4.96, -3.17],
                    [-5.95, -8.53],
                    [-10.11, -3.37],
                    [-22.01, -5.96],
                    [1.98, -9.73],
                    [3.37, -6.16],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [9.04, -11.43],
                    [-4.56, -8.74],
                    [12.85, -7.62],
                    [19.5, -12.86],
                    [3.81, -20.96],
                    [0.59, -7.14],
                    [17.6, 4.76],
                    [16.18, 3.81],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [117.75, 32.25],
                    [-117.75, 32.25],
                    [-75.53, 27.49],
                    [-73.15, -8.25],
                    [-42.22, -8.25],
                    [0, 1.28],
                    [35.09, -14.8],
                    [66.61, -30.87],
                    [82.66, 4.26],
                    [92.77, 27.49],
                    [117.75, 27.49],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [0.533333003521, 0.694118022919, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 13,
      ty: 4,
      nm: 'Rectangle',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [956.25, 891, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ty: 'rc',
              d: 1,
              s: { a: 0, k: [112.5, 15], ix: 2 },
              p: { a: 0, k: [0, 0], ix: 3 },
              r: { a: 0, k: 0, ix: 4 },
              nm: 'Rectangle Path 1',
              mn: 'ADBE Vector Shape - Rect',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.023529413635, 0.027450982262, 0.050980395897, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Rectangle',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 14,
      ty: 4,
      nm: 'Rectangle',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [956.331, 865.114, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ty: 'rc',
              d: 1,
              s: { a: 0, k: [76.662, 37.095], ix: 2 },
              p: { a: 0, k: [0, 0], ix: 3 },
              r: { a: 0, k: 0, ix: 4 },
              nm: 'Rectangle Path 1',
              mn: 'ADBE Vector Shape - Rect',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.023529413635, 0.027450982262, 0.050980395897, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Rectangle',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 15,
      ty: 4,
      nm: 'Rectangle',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [959.99, 730.957, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ty: 'rc',
              d: 1,
              s: { a: 0, k: [305.412, 179.29], ix: 2 },
              p: { a: 0, k: [0, 0], ix: 3 },
              r: { a: 0, k: 0, ix: 4 },
              nm: 'Rectangle Path 1',
              mn: 'ADBE Vector Shape - Rect',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Rectangle',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 16,
      ty: 4,
      nm: 'Rectangle',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [959.99, 735.284, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ty: 'rc',
              d: 1,
              s: { a: 0, k: [344.979, 222.567], ix: 2 },
              p: { a: 0, k: [0, 0], ix: 3 },
              r: { a: 0, k: 0, ix: 4 },
              nm: 'Rectangle Path 1',
              mn: 'ADBE Vector Shape - Rect',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.02352900058, 0.027450999245, 0.050980001688, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Rectangle',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
}

export default LaunchAnimation
