import React from 'react'
import PropTypes from 'prop-types'

const Step = ({ title, anchor, children }) => {
  return (
    <div id={anchor}>
      <h2>{title}</h2>
      {children}
    </div>
  )
}

Step.propTypes = {
  title: PropTypes.string.isRequired,
  anchor: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
}

export default Step
