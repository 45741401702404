const ConversationAnimation = {
  v: '5.2.1',
  fr: 30,
  ip: 0,
  op: 180,
  w: 1920,
  h: 1080,
  nm: 'Guy and girl sitting on a bench black',
  ddd: 0,
  assets: [
    {
      id: 'comp_0',
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: 'Path 4',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [23.03, 17.763, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-12.723, 0.05],
                        [12.727, -0.05],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: { a: 0, k: [0.866666972637, 0.921568989754, 1, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 3.999, ix: 5 },
                  lc: 2,
                  lj: 1,
                  ml: 4,
                  ml2: { a: 0, k: 4, ix: 8 },
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Path',
              np: 2,
              cix: 2,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 4,
          nm: 'Path 3',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [17.569, 29.334, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-14.499, 0],
                        [14.501, 0],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: { a: 0, k: [0.866666972637, 0.921568989754, 1, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 3.999, ix: 5 },
                  lc: 2,
                  lj: 1,
                  ml: 4,
                  ml2: { a: 0, k: 4, ix: 8 },
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Path',
              np: 2,
              cix: 2,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 3,
          ty: 4,
          nm: 'Path 2',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [25.412, 35.35, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [7.11, 9.88],
                        [-24.59, 7.58],
                        [-1.86, 13],
                      ],
                      o: [
                        [0, 0],
                        [-1.47, 14.72],
                        [10.45, -3.22],
                        [5.51, -38.51],
                      ],
                      v: [
                        [9.118, -32.177],
                        [-8.852, -33.217],
                        [-4.982, 32.543],
                        [15.988, 13.983],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.164706006646, 0.44313699007, 0.768626987934, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Path',
              np: 2,
              cix: 2,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
      ],
    },
    {
      id: 'comp_1',
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: 'Path 4',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [38.921, 17.3, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [7.726, 4.838],
                        [-7.724, -4.842],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.717647016048, 0.815685987473, 0.952941000462, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 3.999, ix: 5 },
                  lc: 2,
                  lj: 1,
                  ml: 4,
                  ml2: { a: 0, k: 4, ix: 8 },
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Path',
              np: 2,
              cix: 2,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 4,
          nm: 'Path 3',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [26.792, 21.838, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [7.726, 4.847],
                        [-7.724, -4.843],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.717647016048, 0.815685987473, 0.952941000462, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 3.999, ix: 5 },
                  lc: 2,
                  lj: 1,
                  ml: 4,
                  ml2: { a: 0, k: 4, ix: 8 },
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Path',
              np: 2,
              cix: 2,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 3,
          ty: 4,
          nm: 'Path 2',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [39.681, 27.447, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [11.12, 9.67],
                        [-5.51, -11.9],
                        [-16.57, 5.34],
                      ],
                      o: [
                        [0, 0],
                        [-23.94, 18.88],
                        [5.52, 11.9],
                        [5.26, -9.41],
                      ],
                      v: [
                        [31.788, -26.854],
                        [11.758, -26.074],
                        [-38.292, 23.776],
                        [37.498, 4.226],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.090195998549, 0.294117987156, 0.533333003521, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Path',
              np: 2,
              cix: 2,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
      ],
    },
    {
      id: 'comp_2',
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: 'Oval 4',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [29.032, 17.003, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  d: 1,
                  ty: 'el',
                  s: { a: 0, k: [4.254, 4.254], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  nm: 'Ellipse Path 1',
                  mn: 'ADBE Vector Shape - Ellipse',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.035294000059, 0.623529016972, 0.482353001833, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 18.527, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Oval',
              np: 2,
              cix: 2,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 4,
          nm: 'Oval 3',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [14.008, 14.212, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  d: 1,
                  ty: 'el',
                  s: { a: 0, k: [4.254, 4.254], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  nm: 'Ellipse Path 1',
                  mn: 'ADBE Vector Shape - Ellipse',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.035294000059, 0.623529016972, 0.482353001833, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 18.527, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Oval',
              np: 2,
              cix: 2,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 3,
          ty: 4,
          nm: 'Shape 2',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [19.283, 28.117, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0.67, -0.23],
                        [-0.23, -0.67],
                      ],
                      o: [
                        [-0.23, -0.67],
                        [-0.67, 0.22],
                        [0, 0],
                      ],
                      v: [
                        [-6.875, -3.932],
                        [-8.495, -4.732],
                        [-9.295, -3.112],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 1,
                  ty: 'sh',
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0.6, 0.37],
                        [0.37, -0.6],
                      ],
                      o: [
                        [0.37, -0.6],
                        [-0.6, -0.37],
                        [0, 0],
                      ],
                      v: [
                        [9.18, 1.203],
                        [8.76, -0.557],
                        [7, -0.137],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 2',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 2,
                  ty: 'sh',
                  ix: 3,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [-5.39, -0.94],
                        [0, 0],
                        [0.58, 1.72],
                      ],
                      o: [
                        [0.74, 2.18],
                        [0, 0],
                        [-4.09, -0.71],
                        [0, 0],
                      ],
                      v: [
                        [-9.298, -3.113],
                        [-0.408, 4.617],
                        [0.022, 2.097],
                        [-6.878, -3.933],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 3',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 3,
                  ty: 'sh',
                  ix: 4,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [-1.05, 1.7],
                        [0, 0],
                        [4.19, 0.73],
                      ],
                      o: [
                        [5.28, 0.92],
                        [0, 0],
                        [-0.53, 0.86],
                        [0, 0],
                      ],
                      v: [
                        [-0.412, 4.612],
                        [9.178, 1.202],
                        [6.998, -0.138],
                        [0.028, 2.092],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 4',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'mm',
                  mm: 1,
                  nm: 'Merge Paths 1',
                  mn: 'ADBE Vector Filter - Merge',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.035294000059, 0.623529016972, 0.482353001833, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Shape',
              np: 6,
              cix: 2,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 4,
          ty: 4,
          nm: 'Oval 2',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [20.966, 21.602, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  d: 1,
                  ty: 'el',
                  s: { a: 0, k: [40.19, 40.19], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  nm: 'Ellipse Path 1',
                  mn: 'ADBE Vector Shape - Ellipse',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.262744992971, 0.992156982422, 0.815685987473, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 18.527, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Oval',
              np: 2,
              cix: 2,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
      ],
    },
    {
      id: 'comp_3',
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: 'Oval 4',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [27.635, 16.652, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  d: 1,
                  ty: 'el',
                  s: { a: 0, k: [3.942, 3.942], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  nm: 'Ellipse Path 1',
                  mn: 'ADBE Vector Shape - Ellipse',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.066666997969, 0.2666670084, 0.501960992813, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 27.746, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Oval',
              np: 2,
              cix: 2,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 4,
          nm: 'Oval 3',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [14.304, 11.869, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  d: 1,
                  ty: 'el',
                  s: { a: 0, k: [3.942, 3.942], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  nm: 'Ellipse Path 1',
                  mn: 'ADBE Vector Shape - Ellipse',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.066666997969, 0.2666670084, 0.501960992813, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 27.746, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Oval',
              np: 2,
              cix: 2,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 3,
          ty: 4,
          nm: 'Shape 2',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [17.291, 24.958, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0.72, -0.12],
                        [-0.12, -0.72],
                      ],
                      o: [
                        [-0.12, -0.71],
                        [-0.72, 0.12],
                        [0, 0],
                      ],
                      v: [
                        [-5.797, -4.23],
                        [-7.317, -5.31],
                        [-8.397, -3.79],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 1,
                  ty: 'sh',
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0.55, 0.48],
                        [0.48, -0.55],
                      ],
                      o: [
                        [0.48, -0.55],
                        [-0.55, -0.48],
                        [0, 0],
                      ],
                      v: [
                        [8.097, 2.961],
                        [7.957, 1.101],
                        [6.097, 1.241],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 2',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 2,
                  ty: 'sh',
                  ix: 3,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [-4.85, -1.68],
                        [0, 0],
                        [0.27, 1.63],
                      ],
                      o: [
                        [0.35, 2.13],
                        [0, 0],
                        [-3.56, -1.23],
                        [0, 0],
                      ],
                      v: [
                        [-8.399, -3.795],
                        [-1.329, 4.705],
                        [-0.469, 2.205],
                        [-5.789, -4.225],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 3',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 3,
                  ty: 'sh',
                  ix: 4,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [-1.25, 1.44],
                        [0, 0],
                        [3.67, 1.26],
                      ],
                      o: [
                        [4.75, 1.64],
                        [0, 0],
                        [-0.58, 0.67],
                        [0, 0],
                      ],
                      v: [
                        [-1.329, 4.699],
                        [8.101, 2.969],
                        [6.101, 1.239],
                        [-0.469, 2.209],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 4',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'mm',
                  mm: 1,
                  nm: 'Merge Paths 1',
                  mn: 'ADBE Vector Filter - Merge',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.066666997969, 0.2666670084, 0.501960992813, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Shape',
              np: 6,
              cix: 2,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 4,
          ty: 4,
          nm: 'Oval 2',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [19.573, 19.663, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  d: 1,
                  ty: 'el',
                  s: { a: 0, k: [37.241, 37.241], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  nm: 'Ellipse Path 1',
                  mn: 'ADBE Vector Shape - Ellipse',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.164706006646, 0.44313699007, 0.768626987934, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 27.746, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Oval',
              np: 2,
              cix: 2,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
      ],
    },
  ],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [691.765, 451.745, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [25.47, -6.01],
                    [1.62, 6.33],
                    [-3.39, -9.29],
                    [0, 0],
                    [-3.54, 0.7],
                    [-4.72, 9.29],
                    [0, 0],
                  ],
                  o: [
                    [-25.47, 6.01],
                    [-8.13, 5.66],
                    [0, 0],
                    [11.64, 0.29],
                    [3.53, -0.71],
                    [0, 0],
                    [3.24, 5.01],
                  ],
                  v: [
                    [0.261, -4.398],
                    [-36.449, -15.888],
                    [-36.449, 19.042],
                    [-16.989, 26.562],
                    [22.371, 26.562],
                    [40.941, -2.188],
                    [22.371, -26.948],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.262744992971, 0.992156982422, 0.815685987473, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 2,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [742.865, 552.291, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [12.53, 16.04],
                    [17.8, -4.73],
                    [-3.77, -8.93],
                    [-11.23, -13.01],
                  ],
                  o: [
                    [-11.21, -14.36],
                    [-11.92, 3.17],
                    [8.72, -4.81],
                    [6.54, 7.59],
                  ],
                  v: [
                    [25.935, -6.914],
                    [-19.755, -8.464],
                    [-26.045, 7.986],
                    [1.185, 12.596],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 0.721569001675, 0.466666996479, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 3,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [748.045, 538.674, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [8.86, 0.24],
                    [2.68, -3.17],
                    [0, 0],
                    [0, 0],
                    [0.54, 2.68],
                    [-1.87, 4.22],
                  ],
                  o: [
                    [-7.09, -0.19],
                    [0, 0],
                    [0, 0],
                    [0.2, -2],
                    [-0.68, -3.35],
                    [1.88, -4.22],
                  ],
                  v: [
                    [-3.873, -11.125],
                    [-21.833, 2.095],
                    [-12.953, 6.215],
                    [7.307, 11.125],
                    [7.237, 1.765],
                    [21.697, 3.425],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 0.721569001675, 0.466666996479, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 4,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [907.633, 460.138, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [8.67, 1.86],
                    [1.86, 3.72],
                    [0, 0],
                    [0, 0],
                    [1.15, -2.48],
                    [-0.82, -4.54],
                  ],
                  o: [
                    [-6.94, -1.49],
                    [0, 0],
                    [0, 0],
                    [-0.28, 2],
                    [-1.45, 3.1],
                    [0.83, 4.54],
                  ],
                  v: [
                    [-3.285, 8.247],
                    [-24.395, -10.743],
                    [-8.035, -10.743],
                    [12.815, -10.743],
                    [10.545, -1.663],
                    [24.365, 8.247],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.839215993881, 0.600000023842, 0.384314000607, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 5,
      ty: 4,
      nm: 'Shape',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [622.613, 483.306, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [13.86, 0],
                    [0, -13.86],
                  ],
                  o: [
                    [0, -13.86],
                    [-13.85, 0],
                    [0, 0],
                  ],
                  v: [
                    [25.089, -15.415],
                    [-0.001, -40.505],
                    [-25.09, -15.415],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-25.09, -15.414],
                    [-25.09, 40.506],
                    [25.089, 40.506],
                    [25.089, -15.414],
                    [-25.09, -15.414],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 2',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'mm',
              mm: 1,
              nm: 'Merge Paths 1',
              mn: 'ADBE Vector Filter - Merge',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.262744992971, 0.992156982422, 0.815685987473, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Shape',
          np: 4,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 6,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              n: ['0p833_0p833_0p167_0p167'],
              t: 0,
              s: [0],
              e: [25],
            },
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              n: ['0p833_0p833_0p167_0p167'],
              t: 45,
              s: [25],
              e: [0],
            },
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              n: ['0p833_0p833_0p167_0p167'],
              t: 90,
              s: [0],
              e: [25],
            },
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              n: ['0p833_0p833_0p167_0p167'],
              t: 135,
              s: [25],
              e: [0],
            },
            { t: 180 },
          ],
          ix: 10,
        },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 0,
              s: [687.112, 582.679, 0],
              e: [687.112, 578.679, 0],
              to: [0, -0.66666668653488, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 45,
              s: [687.112, 578.679, 0],
              e: [687.112, 582.679, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 90,
              s: [687.112, 582.679, 0],
              e: [687.112, 578.679, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 135,
              s: [687.112, 578.679, 0],
              e: [687.112, 582.679, 0],
              to: [0, 0, 0],
              ti: [0, -0.66666668653488, 0],
            },
            { t: 180 },
          ],
          ix: 2,
        },
        a: { a: 0, k: [22, 3, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [-9.13, 8.05],
                    [19.17, 0],
                    [-5.94, -18.99],
                    [-7.56, -0.32],
                  ],
                  o: [
                    [0, 0],
                    [-33.33, 0],
                    [-16.18, 0],
                    [2.26, 7.22],
                    [38.86, 1.67],
                  ],
                  v: [
                    [36.169, 10.418],
                    [35.159, -7.272],
                    [-20.701, -19.702],
                    [-33.161, 6.998],
                    [-16.741, 19.528],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.164706006646, 0.44313699007, 0.768626987934, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 7,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [725.868, 693.104, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [10.42, 4.48],
                    [-5.15, 39.21],
                    [-9.7, -3.58],
                    [5.31, -18.33],
                  ],
                  o: [
                    [-16.44, -7.08],
                    [4.16, -31.64],
                    [4.96, 31.98],
                    [-3.26, 11.29],
                  ],
                  v: [
                    [-5.325, 89.766],
                    [-14.555, -59.304],
                    [14.775, -89.594],
                    [14.025, 73.026],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.164706006646, 0.44313699007, 0.768626987934, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 8,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [718.967, 843.599, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [-7.38, 9.68],
                    [-1.37, -19.12],
                    [19.36, 4.57],
                    [0.87, 7.52],
                  ],
                  o: [
                    [0, 0],
                    [2.38, 33.24],
                    [1.15, 16.14],
                    [-7.36, -1.74],
                    [-4.44, -38.65],
                  ],
                  v: [
                    [-14.525, -34.915],
                    [3.395, -34.695],
                    [20.265, 18.595],
                    [-5.475, 32.935],
                    [-19.155, 17.455],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.164706006646, 0.44313699007, 0.768626987934, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 9,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [786.868, 562.376, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-4.65, -10.99],
                    [37.85, -0.11],
                    [3.53, 12.84],
                    [-8.06, 1.36],
                    [0, 0],
                  ],
                  o: [
                    [-12.51, 20.61],
                    [-13.83, 0.04],
                    [1.72, -4.94],
                    [0, 0],
                    [12.3, -1.86],
                  ],
                  v: [
                    [74.521, -10.338],
                    [-45.049, 26.782],
                    [-74.519, 4.802],
                    [-60.649, -5.408],
                    [46.281, -26.478],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.164706006646, 0.44313699007, 0.768626987934, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 10,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [787.339, 569.55, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [18.37, 14.29],
                    [-4.43, 12.72],
                    [-8.06, 1.36],
                    [0, 0],
                    [-4.65, -10.99],
                    [-0.38, -2.13],
                  ],
                  o: [
                    [0, 0],
                    [1.72, -4.94],
                    [0, 0],
                    [12.29, -1.86],
                    [0.82, 1.9],
                    [5.4, 30.42],
                  ],
                  v: [
                    [-68.131, 29.838],
                    [-74.991, -2.372],
                    [-61.121, -12.582],
                    [45.819, -33.652],
                    [74.049, -17.512],
                    [75.859, -11.452],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.090195998549, 0.294117987156, 0.533333003521, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 11,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [713.146, 579.686, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [21.886, -3.095],
                    [-21.884, 3.095],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [1, 0.721569001675, 0.466666996479, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 17.167, ix: 5 },
              lc: 2,
              lj: 1,
              ml: 4,
              ml2: { a: 0, k: 4, ix: 8 },
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 12,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [714.522, 695.273, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [2.44, 0.42],
                    [0.21, 9.91],
                    [-2.61, 40.45],
                    [-5.72, 2.24],
                    [0, 0],
                    [9.9, -40.31],
                    [5.56, -1.11],
                  ],
                  o: [
                    [-6.14, -1.08],
                    [-0.6, -28.05],
                    [1.75, -27.16],
                    [17.69, -6.94],
                    [-1.04, 54.87],
                    [-2.01, 8.21],
                    [-2.44, 0.49],
                  ],
                  v: [
                    [-3.202, 95.111],
                    [-21.902, 78.601],
                    [-25.962, -59.479],
                    [-9.852, -94.619],
                    [4.448, -63.789],
                    [24.668, 81.261],
                    [6.728, 95.141],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.090195998549, 0.294117987156, 0.533333003521, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 13,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [675.94, 526.008, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-8.18, 0],
                    [0, -8.19],
                    [0, 0],
                    [0, 0],
                    [-2.43, -7.81],
                    [7.81, -2.43],
                    [0, 0],
                    [7.1, 14.74],
                    [0, 0],
                  ],
                  o: [
                    [8.18, 0],
                    [0, 0],
                    [0, 0],
                    [7.81, -2.43],
                    [2.42, 7.82],
                    [0, 0],
                    [-12.09, 3.76],
                    [-7.11, -14.74],
                    [0, -8.19],
                  ],
                  v: [
                    [-53.685, -72.634],
                    [-38.865, -57.814],
                    [-38.865, 38.956],
                    [56.475, 9.336],
                    [75.025, 19.086],
                    [64.215, 30.736],
                    [-44.145, 71.626],
                    [-68.495, 53.696],
                    [-75.605, -43.084],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 0.721569001675, 0.466666996479, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 14,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [652.759, 578.675, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-1.36, -23.64],
                    [-9.21, 5.65],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [3.91, 2.39],
                    [9.27, -5.7],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-39.241, 28.654],
                    [-8.681, 24.614],
                    [39.279, 11.794],
                    [13.149, -29.926],
                    [-4.391, -26.466],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.262744992971, 0.992156982422, 0.815685987473, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 15,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [676.196, 615.442, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [9.91, 5.27],
                    [2.09, 7.99],
                    [-16.18, -1.02],
                    [0, 0],
                    [-0.01, -16.21],
                    [27.04, -3.17],
                  ],
                  o: [
                    [-6.96, -3.7],
                    [-2.47, -9.45],
                    [0, 0],
                    [16.17, 1.02],
                    [0.01, 19.74],
                    [-21.02, 2.46],
                  ],
                  v: [
                    [-43.863, 23.893],
                    [-61.853, 5.473],
                    [-45.763, -34.067],
                    [8.257, -23.647],
                    [62.687, -2.457],
                    [25.087, 33.573],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.090195998549, 0.294117987156, 0.533333003521, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 16,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [713.883, 798.772, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [0.46, -21.673],
                    [-0.46, 21.676],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [1, 0.721569001675, 0.466666996479, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 17.167, ix: 5 },
              lc: 2,
              lj: 1,
              ml: 4,
              ml2: { a: 0, k: 4, ix: 8 },
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 17,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [685.573, 341.473, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [16.19, -0.88],
                    [0, 0],
                    [-3.96, -6.94],
                    [0, 0],
                    [5.06, -7.8],
                    [-4.08, -8.98],
                    [4.54, -1.65],
                    [0.59, 14.73],
                    [5.32, 18.6],
                    [-3.98, -6.73],
                    [-0.49, 2.05],
                    [-10.72, 3.09],
                    [-5.59, -7.68],
                    [-15.23, -14.54],
                    [-1.25, -3.21],
                    [-2.83, -1.98],
                    [5.87, -12.91],
                  ],
                  o: [
                    [0, 0],
                    [-3.02, 4.41],
                    [0, 0],
                    [-2.58, -7.6],
                    [-5.07, 7.8],
                    [4.07, 8.99],
                    [-4.55, 1.65],
                    [-0.6, -14.72],
                    [-4.27, -14.88],
                    [-0.77, -1.28],
                    [-3.06, -42.56],
                    [5.86, -1.69],
                    [12.55, -12.96],
                    [1.98, 1.9],
                    [2.66, -0.75],
                    [12.84, 9.01],
                    [0, 0],
                  ],
                  v: [
                    [18.423, 6.559],
                    [0.683, 6.689],
                    [5.693, 28.569],
                    [-6.627, 31.889],
                    [-22.547, 25.649],
                    [-6.127, 53.559],
                    [-16.987, 68.369],
                    [-46.197, 62.279],
                    [-53.967, 2.579],
                    [-37.377, -9.081],
                    [-37.697, -14.161],
                    [-25.137, -69.631],
                    [-6.797, -57.851],
                    [35.823, -63.411],
                    [40.633, -55.611],
                    [48.913, -53.991],
                    [44.013, -0.501],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.164705882353, 0.443137254902, 0.76862745098, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 18,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [730.865, 393.275, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [-6.42, 0.69],
                  ],
                  o: [
                    [0.69, 1.94],
                    [0, 0],
                  ],
                  v: [
                    [-5.296, -2.705],
                    [5.294, 2.625],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.090195998549, 0.294117987156, 0.533333003521, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 2.507, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              ml2: { a: 0, k: 4, ix: 8 },
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 19,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [702.158, 424.365, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [4.34, 6.7],
                    [-3.82, -1.05],
                    [-1.25, -4.01],
                  ],
                  o: [
                    [0, 0],
                    [-0.65, 2.92],
                    [0, 0],
                  ],
                  v: [
                    [-10.686, -13.416],
                    [4.234, -9.766],
                    [10.684, 13.414],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.090195998549, 0.294117987156, 0.533333003521, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 20,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [688.54, 421.571, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-5.69, 15],
                    [3.56, 11.47],
                    [0, 0],
                    [3.42, -4.01],
                    [-2.64, -5.22],
                    [0, 0],
                  ],
                  o: [
                    [5.68, -14.99],
                    [0, 0],
                    [5.88, 3.64],
                    [-3.43, 4.01],
                    [0, 0],
                    [-9.95, 3.42],
                  ],
                  v: [
                    [-28.892, 27.213],
                    [-18.102, -34.637],
                    [4.798, -24.897],
                    [18.168, -8.977],
                    [29.548, 28.153],
                    [8.218, 26.993],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 0.721569001675, 0.466666996479, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 21,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [736.889, 372.267, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0.67, -2.32],
                    [0, 0],
                  ],
                  o: [
                    [2.5, 7.97],
                    [-0.68, 2.32],
                    [0, 0],
                  ],
                  v: [
                    [-5.092, -8.971],
                    [5.058, 3.639],
                    [-1.522, 8.969],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.090195998549, 0.294117987156, 0.533333003521, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 2.507, ix: 5 },
              lc: 2,
              lj: 1,
              ml: 4,
              ml2: { a: 0, k: 4, ix: 8 },
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 22,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [697.747, 375.405, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [-3.04, -33.36],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [-32.88, 16.65],
                    [0, 0],
                  ],
                  v: [
                    [-40.012, -38.214],
                    [31.227, -40.964],
                    [40.018, 36.886],
                    [-39.342, 13.826],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 0.721569001675, 0.466666996479, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 23,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [683.45, 515.78, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-24.47, 25.58],
                    [-13.34, -6.68],
                    [0, 0],
                    [-22.9, 42.01],
                  ],
                  o: [
                    [24.47, -25.58],
                    [13.35, 6.67],
                    [0, 0],
                    [25.58, -55.61],
                  ],
                  v: [
                    [-71.203, -67.8],
                    [65.277, -94.99],
                    [78.507, 85.11],
                    [-74.213, 75.75],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.262744992971, 0.992156982422, 0.815685987473, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 24,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [725.385, 876.734, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, -3.12],
                    [15.24, 0],
                    [6.73, 0.7],
                    [-5.57, 1],
                    [-2.59, 0],
                  ],
                  o: [
                    [0, 3.11],
                    [-8.56, 0],
                    [-5.63, -0.57],
                    [1.57, -0.29],
                    [15.24, 0],
                  ],
                  v: [
                    [24.102, -2.352],
                    [7.562, 4.368],
                    [-17.288, 2.888],
                    [-21.448, -3.592],
                    [-15.268, -4.052],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [
                  0.164706006646,
                  0.44313699007,
                  0.768626987934,
                  0.170000001788,
                ],
                ix: 4,
              },
              o: { a: 0, k: 17, ix: 5 },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 25,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [912.298, 631.749, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [7.69, 0],
                    [0, -0.81],
                    [-0.25, -0.12],
                    [0, -0.11],
                    [-0.25, -0.12],
                    [0, -0.12],
                    [-0.25, -0.12],
                    [0, -0.12],
                    [-0.25, -0.12],
                    [0, -0.12],
                    [-0.25, -0.11],
                    [0, -0.12],
                    [-0.25, -0.11],
                    [0, -0.12],
                    [-7.68, 0],
                    [0, 0.81],
                    [0.26, 0.11],
                    [0, 0.12],
                    [0.26, 0.11],
                    [0, 0.12],
                    [0.26, 0.11],
                    [0, 0.12],
                    [0.26, 0.11],
                    [0, 0.12],
                    [0.26, 0.11],
                    [0, 0.13],
                    [0.26, 0.11],
                    [0, 0.12],
                  ],
                  o: [
                    [-7.68, 0],
                    [0, 0.12],
                    [-0.25, 0.11],
                    [0, 0.13],
                    [-0.25, 0.11],
                    [0, 0.12],
                    [-0.25, 0.11],
                    [0, 0.12],
                    [-0.25, 0.11],
                    [0, 0.12],
                    [-0.25, 0.11],
                    [0, 0.12],
                    [-0.25, 0.11],
                    [0, 0.81],
                    [7.69, 0],
                    [0, -0.12],
                    [0.26, -0.11],
                    [0, -0.12],
                    [0.26, -0.11],
                    [0, -0.12],
                    [0.26, -0.12],
                    [0, -0.12],
                    [0.26, -0.12],
                    [0, -0.12],
                    [0.26, -0.12],
                    [0, -0.11],
                    [0.26, -0.12],
                    [0, -0.81],
                  ],
                  v: [
                    [-0.003, -3.577],
                    [-13.913, -2.107],
                    [-13.523, -1.747],
                    [-13.913, -1.407],
                    [-13.523, -1.047],
                    [-13.913, -0.697],
                    [-13.523, -0.347],
                    [-13.913, 0.003],
                    [-13.523, 0.353],
                    [-13.913, 0.703],
                    [-13.523, 1.053],
                    [-13.913, 1.403],
                    [-13.523, 1.753],
                    [-13.913, 2.103],
                    [-0.003, 3.573],
                    [13.917, 2.103],
                    [13.517, 1.753],
                    [13.917, 1.403],
                    [13.517, 1.053],
                    [13.917, 0.703],
                    [13.517, 0.353],
                    [13.917, 0.003],
                    [13.517, -0.347],
                    [13.917, -0.697],
                    [13.517, -1.047],
                    [13.917, -1.407],
                    [13.517, -1.747],
                    [13.917, -2.107],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.164706006646, 0.44313699007, 0.768626987934, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 26,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [1210.648, 414.347, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [-32.12, 0.29],
                    [-3.42, -43.48],
                    [-8.48, -14.45],
                    [-12.11, -17.26],
                    [14.34, 39.77],
                    [11.44, 18.15],
                    [6.44, 42.81],
                    [18.52, -13.35],
                  ],
                  o: [
                    [0, 0],
                    [8.36, 45.33],
                    [1.74, 22.11],
                    [8.48, 14.45],
                    [12.69, 18.07],
                    [-8.29, -22.98],
                    [-11.43, -18.16],
                    [-6.45, -42.82],
                    [-48.33, 0],
                  ],
                  v: [
                    [-77.354, -45.787],
                    [-37.914, -51.747],
                    [2.426, 35.343],
                    [4.956, 70.293],
                    [48.646, 87.623],
                    [76.526, 72.553],
                    [60.166, -3.547],
                    [25.636, -60.537],
                    [-46.324, -95.457],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [
                  0.9607843137254902,
                  0.4980392156862745,
                  0.12549019607843137,
                  1,
                ],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 27,
      ty: 4,
      nm: 'Rectangle',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [912.295, 564.524, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ty: 'rc',
              d: 1,
              s: { a: 0, k: [16.453, 10.463], ix: 2 },
              p: { a: 0, k: [0, 0], ix: 3 },
              r: { a: 0, k: 2.641, ix: 4 },
              nm: 'Rectangle Path 1',
              mn: 'ADBE Vector Shape - Rect',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.164706006646, 0.44313699007, 0.768626987934, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Rectangle',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 28,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [912.298, 599.735, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [7.99, 0],
                    [0, 0],
                    [0, 8],
                    [0, 0],
                    [-8, 0],
                    [0, -8],
                  ],
                  o: [
                    [0, 8],
                    [0, 0],
                    [-8, 0],
                    [0, 0],
                    [0, -8],
                    [7.99, 0],
                    [0, 0],
                  ],
                  v: [
                    [14.476, 25.898],
                    [2.916, 35.208],
                    [-1.674, 35.208],
                    [-14.474, 25.898],
                    [-14.474, -20.732],
                    [0.006, -35.212],
                    [14.476, -20.732],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.164706006646, 0.44313699007, 0.768626987934, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 29,
      ty: 4,
      nm: 'Oval',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [912.297, 635.177, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              d: 1,
              ty: 'el',
              s: { a: 0, k: [38.877, 2.936], ix: 2 },
              p: { a: 0, k: [0, 0], ix: 3 },
              nm: 'Ellipse Path 1',
              mn: 'ADBE Vector Shape - Ellipse',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [
                  0.164706006646,
                  0.44313699007,
                  0.768626987934,
                  0.20000000298,
                ],
                ix: 4,
              },
              o: { a: 0, k: 20, ix: 5 },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Oval',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 30,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [1026.813, 631.748, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [7.69, 0],
                    [0, -0.81],
                    [-0.26, -0.12],
                    [0, -0.11],
                    [-0.26, -0.12],
                    [0, -0.12],
                    [-0.26, -0.12],
                    [0, -0.12],
                    [-0.26, -0.12],
                    [0, -0.12],
                    [-0.26, -0.11],
                    [0, -0.12],
                    [-0.26, -0.11],
                    [0, -0.12],
                    [-7.68, 0],
                    [0, 0.81],
                    [0.26, 0.11],
                    [0, 0.12],
                    [0.26, 0.11],
                    [0, 0.12],
                    [0.26, 0.11],
                    [0, 0.12],
                    [0.26, 0.11],
                    [0, 0.12],
                    [0.26, 0.11],
                    [0, 0.13],
                    [0.26, 0.11],
                    [0, 0.12],
                  ],
                  o: [
                    [-7.68, 0],
                    [0, 0.12],
                    [-0.26, 0.11],
                    [0, 0.13],
                    [-0.26, 0.11],
                    [0, 0.12],
                    [-0.26, 0.11],
                    [0, 0.12],
                    [-0.26, 0.11],
                    [0, 0.12],
                    [-0.26, 0.11],
                    [0, 0.12],
                    [-0.26, 0.11],
                    [0, 0.81],
                    [7.69, 0],
                    [0, -0.12],
                    [0.26, -0.11],
                    [0, -0.12],
                    [0.26, -0.11],
                    [0, -0.12],
                    [0.26, -0.12],
                    [0, -0.12],
                    [0.26, -0.12],
                    [0, -0.12],
                    [0.26, -0.12],
                    [0, -0.11],
                    [0.26, -0.12],
                    [0, -0.81],
                  ],
                  v: [
                    [-0.003, -3.577],
                    [-13.913, -2.107],
                    [-13.513, -1.747],
                    [-13.913, -1.407],
                    [-13.513, -1.047],
                    [-13.913, -0.697],
                    [-13.513, -0.347],
                    [-13.913, 0.003],
                    [-13.513, 0.353],
                    [-13.913, 0.703],
                    [-13.513, 1.053],
                    [-13.913, 1.403],
                    [-13.513, 1.753],
                    [-13.913, 2.103],
                    [-0.003, 3.573],
                    [13.917, 2.103],
                    [13.517, 1.753],
                    [13.917, 1.403],
                    [13.517, 1.053],
                    [13.917, 0.703],
                    [13.517, 0.353],
                    [13.917, 0.003],
                    [13.517, -0.347],
                    [13.917, -0.697],
                    [13.517, -1.047],
                    [13.917, -1.407],
                    [13.517, -1.747],
                    [13.917, -2.107],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.164706006646, 0.44313699007, 0.768626987934, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 31,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [1026.814, 610.622, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [7.99, 0],
                    [0, 0],
                    [0, 7.99],
                    [0, 0],
                    [-8, 0],
                    [0, -7.99],
                  ],
                  o: [
                    [0, 7.99],
                    [0, 0],
                    [-8, 0],
                    [0, 0],
                    [0, -7.99],
                    [7.99, 0],
                    [0, 0],
                  ],
                  v: [
                    [14.476, 15.018],
                    [2.916, 24.327],
                    [-1.674, 24.327],
                    [-14.474, 15.018],
                    [-14.474, -9.852],
                    [0.006, -24.322],
                    [14.476, -9.852],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.164706006646, 0.44313699007, 0.768626987934, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 32,
      ty: 4,
      nm: 'Rectangle',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [1026.815, 589.793, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ty: 'rc',
              d: 1,
              s: { a: 0, k: [24.653, 10.463], ix: 2 },
              p: { a: 0, k: [0, 0], ix: 3 },
              r: { a: 0, k: 2.641, ix: 4 },
              nm: 'Rectangle Path 1',
              mn: 'ADBE Vector Shape - Rect',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.164706006646, 0.44313699007, 0.768626987934, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Rectangle',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 33,
      ty: 4,
      nm: 'Oval',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [1026.813, 635.177, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              d: 1,
              ty: 'el',
              s: { a: 0, k: [38.877, 2.936], ix: 2 },
              p: { a: 0, k: [0, 0], ix: 3 },
              nm: 'Ellipse Path 1',
              mn: 'ADBE Vector Shape - Ellipse',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [
                  0.164706006646,
                  0.44313699007,
                  0.768626987934,
                  0.20000000298,
                ],
                ix: 4,
              },
              o: { a: 0, k: 20, ix: 5 },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Oval',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 34,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [1201.236, 434.827, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [11.21, 3.21],
                    [16.16, -4.45],
                    [-3.45, 4.3],
                    [1.3, 2.02],
                  ],
                  o: [
                    [-11.78, -3.37],
                    [-2.74, 0.75],
                    [3.46, -4.31],
                    [-1.3, -2.02],
                  ],
                  v: [
                    [19.16, -11.552],
                    [-23.63, -3.032],
                    [-24.43, 11.748],
                    [26.21, 5.068],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [
                  0.9607843137254902,
                  0.4980392156862745,
                  0.12549019607843137,
                  1,
                ],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 35,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [1104.52, 580.113, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-15.38, 13.34],
                    [-15.05, -10.63],
                    [2.79, -4.55],
                    [14.98, 2.52],
                  ],
                  o: [
                    [13.75, -11.94],
                    [5.64, 3.98],
                    [-5.01, 8.16],
                    [-9.88, -1.67],
                  ],
                  v: [
                    [-26.583, 1.445],
                    [23.957, -5.345],
                    [30.487, 5.385],
                    [-6.923, 8.625],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 0.721569001675, 0.466666996479, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 36,
      ty: 4,
      nm: 'Shape',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [1224.149, 483.039, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [-14.44, -1.09],
                    [1.08, -14.44],
                  ],
                  o: [
                    [1.09, -14.44],
                    [14.44, 1.08],
                    [0, 0],
                  ],
                  v: [
                    [-24.234, -15.733],
                    [3.876, -39.903],
                    [28.056, -11.803],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [28.053, -11.801],
                    [24.163, 39.979],
                    [-28.127, 36.049],
                    [-24.237, -15.731],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 2',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'mm',
              mm: 1,
              nm: 'Merge Paths 1',
              mn: 'ADBE Vector Filter - Merge',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.952941000462, 0.949020028114, 0.949020028114, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Shape',
          np: 4,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 37,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [1181.01, 527.158, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [2.35, -19.54],
                    [0, 0],
                    [0, 0],
                    [-11.35, -7.08],
                    [0, 0],
                    [-0.9, 7.47],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [-7.13, 0],
                    [0, 0],
                    [7.48, -0.8],
                    [0, 0],
                    [4.2, -34.92],
                  ],
                  v: [
                    [26.282, -52.423],
                    [22.462, 29.957],
                    [-60.628, 44.547],
                    [-52.208, 61.267],
                    [36.272, 58.297],
                    [50.592, 44.177],
                    [63.822, -22.873],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 0.721569001675, 0.466666996479, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 38,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [1151.902, 395.942, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [5.45, -0.65],
                  ],
                  o: [
                    [-0.19, 1.76],
                    [0, 0],
                  ],
                  v: [
                    [3.868, -3.238],
                    [-3.872, 3.242],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.30980399251, 0.466666996479, 0.576470971107, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 2.479, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              ml2: { a: 0, k: 4, ix: 8 },
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 39,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [1184.829, 411.992, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-2.04, 6.33],
                    [0, 0],
                    [0.15, -3.5],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [5.369, -12.469],
                    [-5.371, -6.309],
                    [-2.671, 12.471],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.30980399251, 0.466666996479, 0.576470971107, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 40,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [1203.811, 410.885, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [7.81, 10.83],
                    [2.15, 31.89],
                    [0, 0],
                    [-3.62, -2.49],
                    [4.3, -4.97],
                    [0, 0],
                  ],
                  o: [
                    [-7.8, -10.84],
                    [0, 0],
                    [-3.95, 4.2],
                    [3.62, 2.49],
                    [0, 0],
                    [8.74, 0.61],
                  ],
                  v: [
                    [25.994, 21.342],
                    [-3.406, -33.288],
                    [-19.066, -12.918],
                    [-26.066, -10.618],
                    [-23.376, 33.292],
                    [-10.626, 29.562],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 0.721569001675, 0.466666996479, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 41,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [1144.232, 381.677, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [-1.18, -1.63],
                    [0, 0],
                  ],
                  o: [
                    [0.28, 6.95],
                    [1.18, 1.63],
                    [0, 0],
                  ],
                  v: [
                    [1.154, -7.533],
                    [-3.246, 5.207],
                    [3.404, 7.537],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.30980399251, 0.466666996479, 0.576470971107, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 2.479, ix: 5 },
              lc: 2,
              lj: 1,
              ml: 4,
              ml2: { a: 0, k: 4, ix: 8 },
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 42,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [1172.376, 379.799, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [-0.77, -27.88],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [28.83, 10.54],
                    [0, 0],
                  ],
                  v: [
                    [29.645, -32.954],
                    [-34.765, -30.034],
                    [-23.485, 31.306],
                    [34.765, -1.594],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 0.721569001675, 0.466666996479, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 43,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [1218.574, 559.401, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [11.53, -98.93],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-24.17, 0],
                    [-1.38, -48.68],
                  ],
                  v: [
                    [8.32, -32.757],
                    [60.74, 36.823],
                    [-62.11, 27.753],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.972549021244, 0.972549021244, 0.972549021244, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 44,
      ty: 0,
      nm: 'sh2',
      refId: 'comp_0',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              n: ['0p833_0p833_0p167_0p167'],
              t: 0,
              s: [0],
              e: [15],
            },
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              n: ['0p833_0p833_0p167_0p167'],
              t: 45,
              s: [15],
              e: [0],
            },
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              n: ['0p833_0p833_0p167_0p167'],
              t: 90,
              s: [0],
              e: [15],
            },
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              n: ['0p833_0p833_0p167_0p167'],
              t: 135,
              s: [15],
              e: [0],
            },
            { t: 180 },
          ],
          ix: 10,
        },
        p: { a: 0, k: [1165.75, 816, 0], ix: 2 },
        a: { a: 0, k: [26.75, 7.75, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 44,
      h: 70,
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 45,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [1160.252, 693.881, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [0, -71.756],
                    [0, 71.754],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.164706006646, 0.44313699007, 0.768626987934, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 50.759, ix: 5 },
              lc: 2,
              lj: 1,
              ml: 4,
              ml2: { a: 0, k: 4, ix: 8 },
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 46,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [1163.597, 799.274, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-2.01, -21.584],
                    [2.01, 21.586],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [1, 0.721569001675, 0.466666996479, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 17.167, ix: 5 },
              lc: 2,
              lj: 1,
              ml: 4,
              ml2: { a: 0, k: 4, ix: 8 },
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 47,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [1208.096, 617.774, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [41.596, -5.012],
                    [-41.594, 5.008],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.164706006646, 0.44313699007, 0.768626987934, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 58.685, ix: 5 },
              lc: 2,
              lj: 1,
              ml: 4,
              ml2: { a: 0, k: 4, ix: 8 },
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 48,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [1081.713, 689.732, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [3.522, -74.408],
                    [-3.518, 74.412],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.090195998549, 0.294117987156, 0.533333003521, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 50.759, ix: 5 },
              lc: 2,
              lj: 1,
              ml: 4,
              ml2: { a: 0, k: 4, ix: 8 },
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 49,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [1168.72, 611.079, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [81.28, -3.415],
                    [-81.28, 3.415],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.090195998549, 0.294117987156, 0.533333003521, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 51.502, ix: 5 },
              lc: 2,
              lj: 1,
              ml: 4,
              ml2: { a: 0, k: 4, ix: 8 },
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 50,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [1222.426, 506.228, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-6.49, 40.92],
                    [-22.05, 6.19],
                    [-5.99, -2.99],
                    [19.29, -87.11],
                    [0, 0],
                  ],
                  o: [
                    [3.59, -22.63],
                    [14.81, -4.16],
                    [21.58, 10.79],
                    [-24.17, 0],
                    [0, -41.33],
                  ],
                  v: [
                    [-52.44, -35.283],
                    [-22.19, -81.663],
                    [13.83, -85.823],
                    [53.26, 87.037],
                    [-58.64, 87.037],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.972549021244, 0.972549021244, 0.972549021244, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 51,
      ty: 0,
      nm: 'sh1',
      refId: 'comp_1',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              n: ['0p833_0p833_0p167_0p167'],
              t: 0,
              s: [0],
              e: [11],
            },
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              n: ['0p833_0p833_0p167_0p167'],
              t: 30,
              s: [11],
              e: [0],
            },
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              n: ['0p833_0p833_0p167_0p167'],
              t: 60,
              s: [0],
              e: [11],
            },
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              n: ['0p833_0p833_0p167_0p167'],
              t: 90,
              s: [11],
              e: [0],
            },
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              n: ['0p833_0p833_0p167_0p167'],
              t: 120,
              s: [0],
              e: [11],
            },
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              n: ['0p833_0p833_0p167_0p167'],
              t: 150,
              s: [11],
              e: [0],
            },
            { t: 180 },
          ],
          ix: 10,
        },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 0,
              s: [1076.5, 814.5, 0],
              e: [1074.5, 813.5, 0],
              to: [-0.33333334326744, -0.16666667163372, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 30,
              s: [1074.5, 813.5, 0],
              e: [1076.5, 814.5, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 60,
              s: [1076.5, 814.5, 0],
              e: [1074.5, 813.5, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 90,
              s: [1074.5, 813.5, 0],
              e: [1076.5, 814.5, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 120,
              s: [1076.5, 814.5, 0],
              e: [1074.5, 813.5, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 150,
              s: [1074.5, 813.5, 0],
              e: [1076.5, 814.5, 0],
              to: [0, 0, 0],
              ti: [-0.33333334326744, -0.16666667163372, 0],
            },
            { t: 180 },
          ],
          ix: 2,
        },
        a: { a: 0, k: [58.5, 14.5, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 80,
      h: 56,
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 52,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [1079.605, 790.458, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [4.78, 0.24],
                    [0.23, -4.81],
                    [0, 0],
                    [-4.78, -0.23],
                    [-0.24, 4.81],
                    [0, 0],
                  ],
                  o: [
                    [-4.78, -0.23],
                    [0, 0],
                    [-0.09, 8.2],
                    [4.78, 0.24],
                    [0, 0],
                    [0.23, -4.81],
                  ],
                  v: [
                    [1.876, -25.962],
                    [-7.194, -17.672],
                    [-10.124, 14.188],
                    [1.666, 25.968],
                    [8.846, 9.608],
                    [10.116, -16.822],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 0.721569001675, 0.466666996479, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 53,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [1058.376, 878.528, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, -1.82],
                    [29.75, 0.02],
                    [9.84, 0.37],
                    [-2.3, 0.69],
                    [-12.64, -0.01],
                  ],
                  o: [
                    [0, 1.82],
                    [-24.09, -0.01],
                    [-2.4, -0.09],
                    [2.39, -0.72],
                    [29.75, 0.02],
                  ],
                  v: [
                    [49.189, -1.357],
                    [16.909, 2.553],
                    [-45.141, 1.193],
                    [-48.391, -1.067],
                    [-27.651, -2.397],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [
                  0.164706006646,
                  0.44313699007,
                  0.768626987934,
                  0.10000000149,
                ],
                ix: 4,
              },
              o: { a: 0, k: 10, ix: 5 },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 54,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [1170.986, 878.145, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-5.53, -1.04],
                    [1.71, -0.41],
                    [5.59, 0],
                    [6.43, 0],
                    [-6.63, 0.72],
                    [-1.74, 0],
                  ],
                  o: [
                    [1.73, 0.32],
                    [-1.5, 0.37],
                    [-7.74, 0],
                    [-6.66, 0],
                    [1.26, -0.13],
                    [11.75, 0],
                  ],
                  v: [
                    [18.025, 0.328],
                    [19.755, 2.308],
                    [9.715, 2.958],
                    [-12.895, 2.958],
                    [-17.125, -2.752],
                    [-12.655, -2.962],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [
                  0.164706006646,
                  0.44313699007,
                  0.768626987934,
                  0.090000003576,
                ],
                ix: 4,
              },
              o: { a: 0, k: 9, ix: 5 },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 55,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [1296.862, 568.499, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-2.15, 0],
                    [0, 2.16],
                    [2.16, 0],
                    [0, -2.15],
                  ],
                  o: [
                    [2.16, 0],
                    [0, -2.15],
                    [-2.15, 0],
                    [0, 2.16],
                  ],
                  v: [
                    [-0.002, 3.898],
                    [3.898, -0.002],
                    [-0.002, -3.902],
                    [-3.902, -0.002],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.811765015125, 0.658824026585, 0.2666670084, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 56,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [1296.862, 520.494, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-2.15, 0],
                    [0, 2.16],
                    [2.16, 0],
                    [0, -2.15],
                  ],
                  o: [
                    [2.16, 0],
                    [0, -2.15],
                    [-2.15, 0],
                    [0, 2.16],
                  ],
                  v: [
                    [-0.002, 3.898],
                    [3.898, -0.002],
                    [-0.002, -3.902],
                    [-3.902, -0.002],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.811765015125, 0.658824026585, 0.2666670084, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 57,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [1296.862, 472.491, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-2.15, 0],
                    [0, 2.16],
                    [2.16, 0],
                    [0, -2.15],
                  ],
                  o: [
                    [2.16, 0],
                    [0, -2.15],
                    [-2.15, 0],
                    [0, 2.16],
                  ],
                  v: [
                    [-0.002, 3.898],
                    [3.898, -0.002],
                    [-0.002, -3.902],
                    [-3.902, -0.002],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.811765015125, 0.658824026585, 0.2666670084, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 58,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [627.546, 568.499, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-2.15, 0],
                    [0, 2.16],
                    [2.16, 0],
                    [0, -2.15],
                  ],
                  o: [
                    [2.16, 0],
                    [0, -2.15],
                    [-2.15, 0],
                    [0, 2.16],
                  ],
                  v: [
                    [-0.002, 3.898],
                    [3.898, -0.002],
                    [-0.002, -3.902],
                    [-3.902, -0.002],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.811765015125, 0.658824026585, 0.2666670084, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 59,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [627.546, 520.494, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-2.15, 0],
                    [0, 2.16],
                    [2.16, 0],
                    [0, -2.15],
                  ],
                  o: [
                    [2.16, 0],
                    [0, -2.15],
                    [-2.15, 0],
                    [0, 2.16],
                  ],
                  v: [
                    [-0.002, 3.898],
                    [3.898, -0.002],
                    [-0.002, -3.902],
                    [-3.902, -0.002],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.811765015125, 0.658824026585, 0.2666670084, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 60,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [627.546, 472.491, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-2.15, 0],
                    [0, 2.16],
                    [2.16, 0],
                    [0, -2.15],
                  ],
                  o: [
                    [2.16, 0],
                    [0, -2.15],
                    [-2.15, 0],
                    [0, 2.16],
                  ],
                  v: [
                    [-0.002, 3.898],
                    [3.898, -0.002],
                    [-0.002, -3.902],
                    [-3.902, -0.002],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.811765015125, 0.658824026585, 0.2666670084, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 61,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              n: ['0p833_0p833_0p167_0p167'],
              t: 0,
              s: [0],
              e: [-7],
            },
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              n: ['0p833_0p833_0p167_0p167'],
              t: 30,
              s: [-7],
              e: [0],
            },
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              n: ['0p833_0p833_0p167_0p167'],
              t: 60,
              s: [0],
              e: [-7],
            },
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              n: ['0p833_0p833_0p167_0p167'],
              t: 90,
              s: [-7],
              e: [0],
            },
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              n: ['0p833_0p833_0p167_0p167'],
              t: 120,
              s: [0],
              e: [-7],
            },
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              n: ['0p833_0p833_0p167_0p167'],
              t: 150,
              s: [-7],
              e: [0],
            },
            { t: 180 },
          ],
          ix: 10,
        },
        p: { a: 0, k: [896.472, 452.462, 0], ix: 2 },
        a: { a: 0, k: [-26.5, 0.75, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [16.64, 7.39],
                    [17.8, -4.74],
                    [-3.77, -8.93],
                    [0, 0],
                    [-1.94, 1.65],
                  ],
                  o: [
                    [-16.64, -7.39],
                    [-11.92, 3.17],
                    [0, 0],
                    [22.62, -5.11],
                    [1.94, -1.65],
                  ],
                  v: [
                    [27.595, -2.299],
                    [-25.615, -7.769],
                    [-36.825, 4.381],
                    [-24.625, 10.101],
                    [35.155, 7.671],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.839215993881, 0.600000023842, 0.384314000607, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 62,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [962.2, 662.793, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [417.2, -29.968],
                    [-417.2, -29.968],
                    [-417.2, 29.972],
                    [417.2, 29.972],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.952941000462, 0.831372976303, 0.513724982738, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 63,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [1327.159, 767.515, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [14.073, -82.545],
                    [-14.077, -82.545],
                    [-14.077, 82.545],
                    [14.073, 82.545],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.811765015125, 0.658824026585, 0.2666670084, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 64,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [597.251, 767.515, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [14.083, -82.545],
                    [-14.077, -82.545],
                    [-14.077, 82.545],
                    [14.083, 82.545],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.811765015125, 0.658824026585, 0.2666670084, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 65,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [962.206, 472.491, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [417.2, -13.438],
                    [-417.2, -13.438],
                    [-417.2, 13.442],
                    [417.2, 13.442],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.952941000462, 0.831372976303, 0.513724982738, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 66,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [962.206, 520.496, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [417.2, -13.438],
                    [-417.2, -13.438],
                    [-417.2, 13.442],
                    [417.2, 13.442],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.952941000462, 0.831372976303, 0.513724982738, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 67,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [772.226, 456.418, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [7.03, -1.3],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-7.03, 1.31],
                  ],
                  v: [
                    [-31.816, 10.002],
                    [13.044, 35.352],
                    [31.814, -12.158],
                    [-25.106, -35.298],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.156863003969, 0.866666972637, 0.694118022919, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 68,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [967.211, 847.312, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-7.38, 2.34],
                    [-1.8, 0.07],
                    [-86.5, -0.48],
                    [-187.94, -0.01],
                    [-0.4, -0.1],
                    [5.74, -0.33],
                    [118.38, 0.89],
                    [197.39, 6.28],
                  ],
                  o: [
                    [1.72, -0.54],
                    [179.66, -7.59],
                    [99.93, 0.56],
                    [0.41, 0],
                    [5.57, 1.39],
                    [-204.63, 11.63],
                    [-83.22, -0.63],
                    [-6.75, -0.21],
                  ],
                  v: [
                    [-385.819, -0.566],
                    [-362.319, -0.676],
                    [0.001, -14.856],
                    [374.891, -5.476],
                    [376.131, -5.326],
                    [391.381, 4.114],
                    [-1.979, 14.484],
                    [-388.559, 9.414],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [
                  0.49411764705882355,
                  0.8274509803921568,
                  0.12941176470588237,
                  1,
                ],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 69,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [962.206, 568.499, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [417.2, -13.438],
                    [-417.2, -13.438],
                    [-417.2, 13.442],
                    [417.2, 13.442],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.952941000462, 0.831372976303, 0.513724982738, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 70,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [1296.859, 532.679, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [19.833, -117.737],
                    [-19.837, -117.737],
                    [-19.837, 117.733],
                    [19.833, 117.733],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.811765015125, 0.658824026585, 0.2666670084, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 71,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [627.549, 532.679, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [19.833, -117.737],
                    [-19.837, -117.737],
                    [-19.837, 117.733],
                    [19.833, 117.733],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.811765015125, 0.658824026585, 0.2666670084, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 72,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [834.965, 461.464, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-3.51, 7.39],
                    [-7.39, -3.51],
                    [0, 0],
                    [-23.26, 8.18],
                    [-6.79, -1.6],
                    [1.88, -7.96],
                    [7.97, 1.88],
                    [0.61, 0.04],
                    [0, 0],
                    [46.39, -13.87],
                    [0, 0],
                  ],
                  o: [
                    [3.52, -7.39],
                    [0, 0],
                    [21.73, -7.52],
                    [7.18, -1.08],
                    [7.97, 1.87],
                    [-1.87, 7.97],
                    [0.04, 0.01],
                    [-0.59, -0.04],
                    [0, 0],
                    [0, 0],
                    [-7.39, -3.52],
                  ],
                  v: [
                    [-93.747, -26.215],
                    [-78.197, -30.175],
                    [-14.357, -1.545],
                    [59.693, -20.305],
                    [91.093, -18.515],
                    [77.153, -6.475],
                    [70.833, 6.805],
                    [70.013, 6.715],
                    [62.533, -5.635],
                    [-24.037, 32.425],
                    [-79.287, 13.435],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.839215993881, 0.600000023842, 0.384314000607, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 73,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [1154.466, 449.454, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-16.75, 15.23],
                    [0, 0],
                    [4.04, -20.1],
                    [0, 0],
                  ],
                  o: [
                    [10.95, -1.11],
                    [-8, 7.87],
                    [0, 0],
                    [-5.98, -10.14],
                  ],
                  v: [
                    [-9.526, -16.977],
                    [26.034, -13.967],
                    [19.284, 17.163],
                    [-23.176, 15.563],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.890196025372, 0.878431022167, 0.878431022167, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 74,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [1152.156, 421.556, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [-0.7, 0.86],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-1.11, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [44.081, 31.184],
                    [-42.759, 31.184],
                    [-43.779, 29.034],
                    [4.991, -31.186],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.839215993881, 0.600000023842, 0.384314000607, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 28.313, ix: 5 },
              lc: 2,
              lj: 1,
              ml: 4,
              ml2: { a: 0, k: 4, ix: 8 },
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 75,
      ty: 0,
      nm: 'sm2',
      refId: 'comp_2',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 10, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 70,
              s: [1202.25, 474.5, 0],
              e: [1218.25, 224.5, 0],
              to: [18.6666660308838, -135.83332824707, 0],
              ti: [-56.2892456054688, 142.630279541016, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 100,
              s: [1218.25, 224.5, 0],
              e: [1132.25, -93.5, 0],
              to: [69.7252807617188, -176.675582885742, 0],
              ti: [0, 0, 0],
            },
            { t: 130 },
          ],
          ix: 2,
        },
        a: { a: 0, k: [21, 21.5, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 42,
      h: 43,
      ip: 70,
      op: 250,
      st: 70,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 76,
      ty: 0,
      nm: 'sm2',
      refId: 'comp_2',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 10, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 110,
              s: [1202.25, 474.5, 0],
              e: [1218.25, 224.5, 0],
              to: [18.6666660308838, -135.83332824707, 0],
              ti: [-56.2892456054688, 142.630279541016, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 140,
              s: [1218.25, 224.5, 0],
              e: [1132.25, -93.5, 0],
              to: [69.7252807617188, -176.675582885742, 0],
              ti: [0, 0, 0],
            },
            { t: 170 },
          ],
          ix: 2,
        },
        a: { a: 0, k: [21, 21.5, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 42,
      h: 43,
      ip: 110,
      op: 290,
      st: 110,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 77,
      ty: 0,
      nm: 'sm2',
      refId: 'comp_2',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 10, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 30,
              s: [1202.25, 474.5, 0],
              e: [1218.25, 224.5, 0],
              to: [18.6666660308838, -135.83332824707, 0],
              ti: [-56.2892456054688, 142.630279541016, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 60,
              s: [1218.25, 224.5, 0],
              e: [1132.25, -93.5, 0],
              to: [69.7252807617188, -176.675582885742, 0],
              ti: [0, 0, 0],
            },
            { t: 90 },
          ],
          ix: 2,
        },
        a: { a: 0, k: [21, 21.5, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 42,
      h: 43,
      ip: 30,
      op: 210,
      st: 30,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 78,
      ty: 0,
      nm: 'sm2',
      refId: 'comp_2',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 10, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 60,
              s: [1202.25, 474.5, 0],
              e: [1158.25, 223.5, 0],
              to: [123.666664123535, -115.833335876465, 0],
              ti: [11.6666669845581, 94.6666641235352, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 90,
              s: [1158.25, 223.5, 0],
              e: [1132.25, -93.5, 0],
              to: [-11.6666669845581, -94.6666641235352, 0],
              ti: [-195.66667175293, 70.8333358764648, 0],
            },
            { t: 120 },
          ],
          ix: 2,
        },
        a: { a: 0, k: [21, 21.5, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 42,
      h: 43,
      ip: 60,
      op: 240,
      st: 60,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 79,
      ty: 0,
      nm: 'sm2',
      refId: 'comp_2',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 10, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 100,
              s: [1202.25, 474.5, 0],
              e: [1158.25, 223.5, 0],
              to: [123.666664123535, -115.833335876465, 0],
              ti: [11.6666669845581, 94.6666641235352, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 130,
              s: [1158.25, 223.5, 0],
              e: [1132.25, -93.5, 0],
              to: [-11.6666669845581, -94.6666641235352, 0],
              ti: [-195.66667175293, 70.8333358764648, 0],
            },
            { t: 160 },
          ],
          ix: 2,
        },
        a: { a: 0, k: [21, 21.5, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 42,
      h: 43,
      ip: 100,
      op: 280,
      st: 100,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 80,
      ty: 0,
      nm: 'sm2',
      refId: 'comp_2',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 10, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 20,
              s: [1202.25, 474.5, 0],
              e: [1158.25, 223.5, 0],
              to: [123.666664123535, -115.833335876465, 0],
              ti: [11.6666669845581, 94.6666641235352, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 50,
              s: [1158.25, 223.5, 0],
              e: [1132.25, -93.5, 0],
              to: [-11.6666669845581, -94.6666641235352, 0],
              ti: [-195.66667175293, 70.8333358764648, 0],
            },
            { t: 80 },
          ],
          ix: 2,
        },
        a: { a: 0, k: [21, 21.5, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 42,
      h: 43,
      ip: 20,
      op: 200,
      st: 20,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 81,
      ty: 0,
      nm: 'sm2',
      refId: 'comp_2',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 10, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 50,
              s: [1202.25, 474.5, 0],
              e: [1060.25, 259.5, 0],
              to: [-216.33332824707, -36.8333320617676, 0],
              ti: [-26.7512245178223, 24.155969619751, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 80,
              s: [1060.25, 259.5, 0],
              e: [1132.25, -93.5, 0],
              to: [212.995498657227, -192.331848144531, 0],
              ti: [0, 0, 0],
            },
            { t: 110 },
          ],
          ix: 2,
        },
        a: { a: 0, k: [21, 21.5, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 42,
      h: 43,
      ip: 50,
      op: 230,
      st: 50,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 82,
      ty: 0,
      nm: 'sm2',
      refId: 'comp_2',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 10, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 90,
              s: [1202.25, 474.5, 0],
              e: [1060.25, 259.5, 0],
              to: [-216.33332824707, -36.8333320617676, 0],
              ti: [-26.7512245178223, 24.155969619751, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 120,
              s: [1060.25, 259.5, 0],
              e: [1132.25, -93.5, 0],
              to: [212.995498657227, -192.331848144531, 0],
              ti: [0, 0, 0],
            },
            { t: 150 },
          ],
          ix: 2,
        },
        a: { a: 0, k: [21, 21.5, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 42,
      h: 43,
      ip: 90,
      op: 270,
      st: 90,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 83,
      ty: 0,
      nm: 'sm2',
      refId: 'comp_2',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 10, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 10,
              s: [1202.25, 474.5, 0],
              e: [1060.25, 259.5, 0],
              to: [-216.33332824707, -36.8333320617676, 0],
              ti: [-26.7512245178223, 24.155969619751, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 40,
              s: [1060.25, 259.5, 0],
              e: [1132.25, -93.5, 0],
              to: [212.995498657227, -192.331848144531, 0],
              ti: [0, 0, 0],
            },
            { t: 70 },
          ],
          ix: 2,
        },
        a: { a: 0, k: [21, 21.5, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 42,
      h: 43,
      ip: 10,
      op: 190,
      st: 10,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 84,
      ty: 0,
      nm: 'sm2',
      refId: 'comp_2',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 10, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 120,
              s: [1202.25, 474.5, 0],
              e: [1158.25, 223.5, 0],
              to: [-134.33332824707, -89.8333358764648, 0],
              ti: [-24.2892475128174, 26.6302719116211, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 150,
              s: [1158.25, 223.5, 0],
              e: [1132.25, -93.5, 0],
              to: [127.995498657227, -140.331848144531, 0],
              ti: [0, 0, 0],
            },
            { t: 180 },
          ],
          ix: 2,
        },
        a: { a: 0, k: [21, 21.5, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 42,
      h: 43,
      ip: 120,
      op: 300,
      st: 120,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 85,
      ty: 0,
      nm: 'sm2',
      refId: 'comp_2',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 10, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 40,
              s: [1202.25, 474.5, 0],
              e: [1158.25, 223.5, 0],
              to: [-134.33332824707, -89.8333358764648, 0],
              ti: [-24.2892475128174, 26.6302719116211, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 70,
              s: [1158.25, 223.5, 0],
              e: [1132.25, -93.5, 0],
              to: [127.995498657227, -140.331848144531, 0],
              ti: [0, 0, 0],
            },
            { t: 100 },
          ],
          ix: 2,
        },
        a: { a: 0, k: [21, 21.5, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 42,
      h: 43,
      ip: 40,
      op: 220,
      st: 40,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 86,
      ty: 0,
      nm: 'sm2',
      refId: 'comp_2',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 10, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 80,
              s: [1202.25, 474.5, 0],
              e: [1158.25, 223.5, 0],
              to: [-134.33332824707, -89.8333358764648, 0],
              ti: [-24.2892475128174, 26.6302719116211, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 110,
              s: [1158.25, 223.5, 0],
              e: [1132.25, -93.5, 0],
              to: [127.995498657227, -140.331848144531, 0],
              ti: [0, 0, 0],
            },
            { t: 140 },
          ],
          ix: 2,
        },
        a: { a: 0, k: [21, 21.5, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 42,
      h: 43,
      ip: 80,
      op: 260,
      st: 80,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 87,
      ty: 0,
      nm: 'sm2',
      refId: 'comp_2',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 10, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 0,
              s: [1202.25, 474.5, 0],
              e: [1158.25, 223.5, 0],
              to: [-134.33332824707, -89.8333358764648, 0],
              ti: [-24.2892475128174, 26.6302719116211, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 30,
              s: [1158.25, 223.5, 0],
              e: [1132.25, -93.5, 0],
              to: [127.995498657227, -140.331848144531, 0],
              ti: [0, 0, 0],
            },
            { t: 60 },
          ],
          ix: 2,
        },
        a: { a: 0, k: [21, 21.5, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 42,
      h: 43,
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 88,
      ty: 0,
      nm: 'sm1',
      refId: 'comp_3',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 110,
              s: [1206.5, 481.5, 0],
              e: [1199.5, 158.5, 0],
              to: [-113.333335876465, -42.5, 0],
              ti: [-60.2473030090332, 107.992210388184, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 140,
              s: [1199.5, 158.5, 0],
              e: [1245.5, -89.5, 0],
              to: [18.6634120941162, -33.4538345336914, 0],
              ti: [11.5, 131.66667175293, 0],
            },
            { t: 170 },
          ],
          ix: 2,
        },
        a: { a: 0, k: [19.5, 19.5, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 39,
      h: 39,
      ip: 110,
      op: 290,
      st: 110,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 89,
      ty: 0,
      nm: 'sm1',
      refId: 'comp_3',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 70,
              s: [1206.5, 481.5, 0],
              e: [1199.5, 158.5, 0],
              to: [-113.333335876465, -42.5, 0],
              ti: [-60.2473030090332, 107.992210388184, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 100,
              s: [1199.5, 158.5, 0],
              e: [1245.5, -89.5, 0],
              to: [18.6634120941162, -33.4538345336914, 0],
              ti: [11.5, 131.66667175293, 0],
            },
            { t: 130 },
          ],
          ix: 2,
        },
        a: { a: 0, k: [19.5, 19.5, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 39,
      h: 39,
      ip: 70,
      op: 250,
      st: 70,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 90,
      ty: 0,
      nm: 'sm1',
      refId: 'comp_3',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 30,
              s: [1206.5, 481.5, 0],
              e: [1199.5, 158.5, 0],
              to: [-113.333335876465, -42.5, 0],
              ti: [-60.2473030090332, 107.992210388184, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 60,
              s: [1199.5, 158.5, 0],
              e: [1245.5, -89.5, 0],
              to: [18.6634120941162, -33.4538345336914, 0],
              ti: [11.5, 131.66667175293, 0],
            },
            { t: 90 },
          ],
          ix: 2,
        },
        a: { a: 0, k: [19.5, 19.5, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 39,
      h: 39,
      ip: 30,
      op: 210,
      st: 30,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 91,
      ty: 0,
      nm: 'sm1',
      refId: 'comp_3',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 100,
              s: [1206.5, 481.5, 0],
              e: [1095.5, 234.5, 0],
              to: [-113.333335876465, -42.5, 0],
              ti: [-60.2473030090332, 107.992210388184, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 130,
              s: [1095.5, 234.5, 0],
              e: [1286.5, -38.5, 0],
              to: [18.6634120941162, -33.4538345336914, 0],
              ti: [11.5, 131.66667175293, 0],
            },
            { t: 160 },
          ],
          ix: 2,
        },
        a: { a: 0, k: [19.5, 19.5, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 39,
      h: 39,
      ip: 100,
      op: 280,
      st: 100,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 92,
      ty: 0,
      nm: 'sm1',
      refId: 'comp_3',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 60,
              s: [1206.5, 481.5, 0],
              e: [1095.5, 234.5, 0],
              to: [-113.333335876465, -42.5, 0],
              ti: [-60.2473030090332, 107.992210388184, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 90,
              s: [1095.5, 234.5, 0],
              e: [1286.5, -38.5, 0],
              to: [18.6634120941162, -33.4538345336914, 0],
              ti: [11.5, 131.66667175293, 0],
            },
            { t: 120 },
          ],
          ix: 2,
        },
        a: { a: 0, k: [19.5, 19.5, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 39,
      h: 39,
      ip: 60,
      op: 240,
      st: 60,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 93,
      ty: 0,
      nm: 'sm1',
      refId: 'comp_3',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 20,
              s: [1206.5, 481.5, 0],
              e: [1095.5, 234.5, 0],
              to: [-113.333335876465, -42.5, 0],
              ti: [-60.2473030090332, 107.992210388184, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 50,
              s: [1095.5, 234.5, 0],
              e: [1286.5, -38.5, 0],
              to: [18.6634120941162, -33.4538345336914, 0],
              ti: [11.5, 131.66667175293, 0],
            },
            { t: 80 },
          ],
          ix: 2,
        },
        a: { a: 0, k: [19.5, 19.5, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 39,
      h: 39,
      ip: 20,
      op: 200,
      st: 20,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 94,
      ty: 0,
      nm: 'sm1',
      refId: 'comp_3',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 90,
              s: [1206.5, 481.5, 0],
              e: [1215.5, 236.5, 0],
              to: [-101.333335876465, -93.5, 0],
              ti: [-27.1666660308838, 69.1666641235352, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 120,
              s: [1215.5, 236.5, 0],
              e: [1051.5, -53.5, 0],
              to: [33.9383430480957, -86.4074325561523, 0],
              ti: [11.5, 131.66667175293, 0],
            },
            { t: 150 },
          ],
          ix: 2,
        },
        a: { a: 0, k: [19.5, 19.5, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 39,
      h: 39,
      ip: 90,
      op: 270,
      st: 90,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 95,
      ty: 0,
      nm: 'sm1',
      refId: 'comp_3',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 50,
              s: [1206.5, 481.5, 0],
              e: [1215.5, 236.5, 0],
              to: [-101.333335876465, -93.5, 0],
              ti: [-27.1666660308838, 69.1666641235352, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 80,
              s: [1215.5, 236.5, 0],
              e: [1051.5, -53.5, 0],
              to: [33.9383430480957, -86.4074325561523, 0],
              ti: [11.5, 131.66667175293, 0],
            },
            { t: 110 },
          ],
          ix: 2,
        },
        a: { a: 0, k: [19.5, 19.5, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 39,
      h: 39,
      ip: 50,
      op: 230,
      st: 50,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 96,
      ty: 0,
      nm: 'sm1',
      refId: 'comp_3',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 10,
              s: [1206.5, 481.5, 0],
              e: [1215.5, 236.5, 0],
              to: [-101.333335876465, -93.5, 0],
              ti: [-27.1666660308838, 69.1666641235352, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 40,
              s: [1215.5, 236.5, 0],
              e: [1051.5, -53.5, 0],
              to: [33.9383430480957, -86.4074325561523, 0],
              ti: [11.5, 131.66667175293, 0],
            },
            { t: 70 },
          ],
          ix: 2,
        },
        a: { a: 0, k: [19.5, 19.5, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 39,
      h: 39,
      ip: 10,
      op: 190,
      st: 10,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 97,
      ty: 0,
      nm: 'sm1',
      refId: 'comp_3',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 80,
              s: [1206.5, 481.5, 0],
              e: [1276.5, 286.5, 0],
              to: [91.6666641235352, -57.5, 0],
              ti: [44.8333320617676, 70.1666641235352, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 110,
              s: [1276.5, 286.5, 0],
              e: [1051.5, -53.5, 0],
              to: [-49.9842185974121, -78.2280883789062, 0],
              ti: [11.5, 131.66667175293, 0],
            },
            { t: 140 },
          ],
          ix: 2,
        },
        a: { a: 0, k: [19.5, 19.5, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 39,
      h: 39,
      ip: 80,
      op: 260,
      st: 80,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 98,
      ty: 0,
      nm: 'sm1',
      refId: 'comp_3',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 40,
              s: [1206.5, 481.5, 0],
              e: [1276.5, 286.5, 0],
              to: [91.6666641235352, -57.5, 0],
              ti: [44.8333320617676, 70.1666641235352, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 70,
              s: [1276.5, 286.5, 0],
              e: [1051.5, -53.5, 0],
              to: [-49.9842185974121, -78.2280883789062, 0],
              ti: [11.5, 131.66667175293, 0],
            },
            { t: 100 },
          ],
          ix: 2,
        },
        a: { a: 0, k: [19.5, 19.5, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 39,
      h: 39,
      ip: 40,
      op: 220,
      st: 40,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 99,
      ty: 0,
      nm: 'sm1',
      refId: 'comp_3',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 0,
              s: [1206.5, 481.5, 0],
              e: [1276.5, 286.5, 0],
              to: [91.6666641235352, -57.5, 0],
              ti: [44.8333320617676, 70.1666641235352, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 30,
              s: [1276.5, 286.5, 0],
              e: [1051.5, -53.5, 0],
              to: [-49.9842185974121, -78.2280883789062, 0],
              ti: [11.5, 131.66667175293, 0],
            },
            { t: 60 },
          ],
          ix: 2,
        },
        a: { a: 0, k: [19.5, 19.5, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 39,
      h: 39,
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 100,
      ty: 0,
      nm: 'sm2',
      refId: 'comp_2',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 110,
              s: [698, 455.5, 0],
              e: [733, 266.5, 0],
              to: [5.83333349227905, -31.5, 0],
              ti: [5.33333349227905, 87.5, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 140,
              s: [733, 266.5, 0],
              e: [666, -69.5, 0],
              to: [-5.33333349227905, -87.5, 0],
              ti: [-0.16666667163372, 139.83332824707, 0],
            },
            { t: 170 },
          ],
          ix: 2,
        },
        a: { a: 0, k: [21, 21.5, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 42,
      h: 43,
      ip: 110,
      op: 290,
      st: 110,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 101,
      ty: 0,
      nm: 'sm2',
      refId: 'comp_2',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 70,
              s: [698, 455.5, 0],
              e: [733, 266.5, 0],
              to: [5.83333349227905, -31.5, 0],
              ti: [5.33333349227905, 87.5, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 100,
              s: [733, 266.5, 0],
              e: [666, -69.5, 0],
              to: [-5.33333349227905, -87.5, 0],
              ti: [-0.16666667163372, 139.83332824707, 0],
            },
            { t: 130 },
          ],
          ix: 2,
        },
        a: { a: 0, k: [21, 21.5, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 42,
      h: 43,
      ip: 70,
      op: 250,
      st: 70,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 102,
      ty: 0,
      nm: 'sm2',
      refId: 'comp_2',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 30,
              s: [698, 455.5, 0],
              e: [733, 266.5, 0],
              to: [5.83333349227905, -31.5, 0],
              ti: [5.33333349227905, 87.5, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 60,
              s: [733, 266.5, 0],
              e: [666, -69.5, 0],
              to: [-5.33333349227905, -87.5, 0],
              ti: [-0.16666667163372, 139.83332824707, 0],
            },
            { t: 90 },
          ],
          ix: 2,
        },
        a: { a: 0, k: [21, 21.5, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 42,
      h: 43,
      ip: 30,
      op: 210,
      st: 30,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 103,
      ty: 0,
      nm: 'sm2',
      refId: 'comp_2',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 100,
              s: [698, 455.5, 0],
              e: [620, 230.5, 0],
              to: [-13, -37.5, 0],
              ti: [-0.83333331346512, 95.3333358764648, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 130,
              s: [620, 230.5, 0],
              e: [703, -116.5, 0],
              to: [0.83333331346512, -95.3333358764648, 0],
              ti: [80.8333358764648, 200.83332824707, 0],
            },
            { t: 160 },
          ],
          ix: 2,
        },
        a: { a: 0, k: [21, 21.5, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 42,
      h: 43,
      ip: 100,
      op: 280,
      st: 100,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 104,
      ty: 0,
      nm: 'sm2',
      refId: 'comp_2',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 60,
              s: [698, 455.5, 0],
              e: [620, 230.5, 0],
              to: [-13, -37.5, 0],
              ti: [-0.83333331346512, 95.3333358764648, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 90,
              s: [620, 230.5, 0],
              e: [703, -116.5, 0],
              to: [0.83333331346512, -95.3333358764648, 0],
              ti: [80.8333358764648, 200.83332824707, 0],
            },
            { t: 120 },
          ],
          ix: 2,
        },
        a: { a: 0, k: [21, 21.5, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 42,
      h: 43,
      ip: 60,
      op: 240,
      st: 60,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 105,
      ty: 0,
      nm: 'sm2',
      refId: 'comp_2',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 20,
              s: [698, 455.5, 0],
              e: [620, 230.5, 0],
              to: [-13, -37.5, 0],
              ti: [-0.83333331346512, 95.3333358764648, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 50,
              s: [620, 230.5, 0],
              e: [703, -116.5, 0],
              to: [0.83333331346512, -95.3333358764648, 0],
              ti: [80.8333358764648, 200.83332824707, 0],
            },
            { t: 80 },
          ],
          ix: 2,
        },
        a: { a: 0, k: [21, 21.5, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 42,
      h: 43,
      ip: 20,
      op: 200,
      st: 20,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 106,
      ty: 0,
      nm: 'sm2',
      refId: 'comp_2',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 90,
              s: [698, 455.5, 0],
              e: [466, 280.5, 0],
              to: [-38.6666679382324, -29.1666660308838, 0],
              ti: [-4.16666650772095, 96, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 120,
              s: [466, 280.5, 0],
              e: [723, -120.5, 0],
              to: [4.16666650772095, -96, 0],
              ti: [-0.16666667163372, 139.83332824707, 0],
            },
            { t: 150 },
          ],
          ix: 2,
        },
        a: { a: 0, k: [21, 21.5, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 42,
      h: 43,
      ip: 90,
      op: 270,
      st: 90,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 107,
      ty: 0,
      nm: 'sm2',
      refId: 'comp_2',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 50,
              s: [698, 455.5, 0],
              e: [466, 280.5, 0],
              to: [-38.6666679382324, -29.1666660308838, 0],
              ti: [-4.16666650772095, 96, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 80,
              s: [466, 280.5, 0],
              e: [723, -120.5, 0],
              to: [4.16666650772095, -96, 0],
              ti: [-0.16666667163372, 139.83332824707, 0],
            },
            { t: 110 },
          ],
          ix: 2,
        },
        a: { a: 0, k: [21, 21.5, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 42,
      h: 43,
      ip: 50,
      op: 230,
      st: 50,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 108,
      ty: 0,
      nm: 'sm2',
      refId: 'comp_2',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 10,
              s: [698, 455.5, 0],
              e: [466, 280.5, 0],
              to: [-38.6666679382324, -29.1666660308838, 0],
              ti: [-4.16666650772095, 96, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 40,
              s: [466, 280.5, 0],
              e: [723, -120.5, 0],
              to: [4.16666650772095, -96, 0],
              ti: [-0.16666667163372, 139.83332824707, 0],
            },
            { t: 70 },
          ],
          ix: 2,
        },
        a: { a: 0, k: [21, 21.5, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 42,
      h: 43,
      ip: 10,
      op: 190,
      st: 10,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 109,
      ty: 0,
      nm: 'sm2',
      refId: 'comp_2',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 80,
              s: [698, 455.5, 0],
              e: [767, 231.5, 0],
              to: [11.5, -37.3333320617676, 0],
              ti: [33.3333320617676, 91.1666641235352, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 110,
              s: [767, 231.5, 0],
              e: [498, -91.5, 0],
              to: [-33.3333320617676, -91.1666641235352, 0],
              ti: [-0.16666667163372, 139.83332824707, 0],
            },
            { t: 140 },
          ],
          ix: 2,
        },
        a: { a: 0, k: [21, 21.5, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 42,
      h: 43,
      ip: 80,
      op: 260,
      st: 80,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 110,
      ty: 0,
      nm: 'sm2',
      refId: 'comp_2',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 40,
              s: [698, 455.5, 0],
              e: [767, 231.5, 0],
              to: [11.5, -37.3333320617676, 0],
              ti: [33.3333320617676, 91.1666641235352, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 70,
              s: [767, 231.5, 0],
              e: [498, -91.5, 0],
              to: [-33.3333320617676, -91.1666641235352, 0],
              ti: [-0.16666667163372, 139.83332824707, 0],
            },
            { t: 100 },
          ],
          ix: 2,
        },
        a: { a: 0, k: [21, 21.5, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 42,
      h: 43,
      ip: 40,
      op: 220,
      st: 40,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 111,
      ty: 0,
      nm: 'sm2',
      refId: 'comp_2',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 0,
              s: [698, 455.5, 0],
              e: [767, 231.5, 0],
              to: [11.5, -37.3333320617676, 0],
              ti: [33.3333320617676, 91.1666641235352, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 30,
              s: [767, 231.5, 0],
              e: [498, -91.5, 0],
              to: [-33.3333320617676, -91.1666641235352, 0],
              ti: [-0.16666667163372, 139.83332824707, 0],
            },
            { t: 60 },
          ],
          ix: 2,
        },
        a: { a: 0, k: [21, 21.5, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 42,
      h: 43,
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 112,
      ty: 0,
      nm: 'sm1',
      refId: 'comp_3',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: -45, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 110,
              s: [671.75, 465, 0],
              e: [836.75, 257, 0],
              to: [119.166664123535, -79, 0],
              ti: [3.33050584793091, 67.2099533081055, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 140,
              s: [836.75, 257, 0],
              e: [698.75, -49, 0],
              to: [-9.53463649749756, -192.409942626953, 0],
              ti: [0, 0, 0],
            },
            { t: 170 },
          ],
          ix: 2,
        },
        a: { a: 0, k: [19.5, 19.5, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 39,
      h: 39,
      ip: 110,
      op: 290,
      st: 110,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 113,
      ty: 0,
      nm: 'sm1',
      refId: 'comp_3',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: -45, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 70,
              s: [671.75, 465, 0],
              e: [836.75, 257, 0],
              to: [119.166664123535, -79, 0],
              ti: [3.33050584793091, 67.2099533081055, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 100,
              s: [836.75, 257, 0],
              e: [698.75, -49, 0],
              to: [-9.53463649749756, -192.409942626953, 0],
              ti: [0, 0, 0],
            },
            { t: 130 },
          ],
          ix: 2,
        },
        a: { a: 0, k: [19.5, 19.5, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 39,
      h: 39,
      ip: 70,
      op: 250,
      st: 70,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 114,
      ty: 0,
      nm: 'sm1',
      refId: 'comp_3',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: -45, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 30,
              s: [671.75, 465, 0],
              e: [836.75, 257, 0],
              to: [119.166664123535, -79, 0],
              ti: [3.33050584793091, 67.2099533081055, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 60,
              s: [836.75, 257, 0],
              e: [698.75, -49, 0],
              to: [-9.53463649749756, -192.409942626953, 0],
              ti: [0, 0, 0],
            },
            { t: 90 },
          ],
          ix: 2,
        },
        a: { a: 0, k: [19.5, 19.5, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 39,
      h: 39,
      ip: 30,
      op: 210,
      st: 30,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 115,
      ty: 0,
      nm: 'sm1',
      refId: 'comp_3',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: -45, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 100,
              s: [671.75, 465, 0],
              e: [482.75, 232, 0],
              to: [-120.833335876465, -53, 0],
              ti: [2.33050584793091, 139.2099609375, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 130,
              s: [482.75, 232, 0],
              e: [698.75, -49, 0],
              to: [-3.22462439537048, -192.619049072266, 0],
              ti: [0, 0, 0],
            },
            { t: 160 },
          ],
          ix: 2,
        },
        a: { a: 0, k: [19.5, 19.5, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 39,
      h: 39,
      ip: 100,
      op: 280,
      st: 100,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 116,
      ty: 0,
      nm: 'sm1',
      refId: 'comp_3',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: -45, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 60,
              s: [671.75, 465, 0],
              e: [482.75, 232, 0],
              to: [-120.833335876465, -53, 0],
              ti: [2.33050584793091, 139.2099609375, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 90,
              s: [482.75, 232, 0],
              e: [698.75, -49, 0],
              to: [-3.22462439537048, -192.619049072266, 0],
              ti: [0, 0, 0],
            },
            { t: 120 },
          ],
          ix: 2,
        },
        a: { a: 0, k: [19.5, 19.5, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 39,
      h: 39,
      ip: 60,
      op: 240,
      st: 60,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 117,
      ty: 0,
      nm: 'sm1',
      refId: 'comp_3',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: -45, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 20,
              s: [671.75, 465, 0],
              e: [482.75, 232, 0],
              to: [-120.833335876465, -53, 0],
              ti: [2.33050584793091, 139.2099609375, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 50,
              s: [482.75, 232, 0],
              e: [698.75, -49, 0],
              to: [-3.22462439537048, -192.619049072266, 0],
              ti: [0, 0, 0],
            },
            { t: 80 },
          ],
          ix: 2,
        },
        a: { a: 0, k: [19.5, 19.5, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 39,
      h: 39,
      ip: 20,
      op: 200,
      st: 20,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 118,
      ty: 0,
      nm: 'sm1',
      refId: 'comp_3',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: -45, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 90,
              s: [671.75, 465, 0],
              e: [507.75, 143, 0],
              to: [196.16667175293, -228, 0],
              ti: [-57.6694946289062, 152.2099609375, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 120,
              s: [507.75, 143, 0],
              e: [698.75, -49, 0],
              to: [68.2551498413086, -180.149185180664, 0],
              ti: [0, 0, 0],
            },
            { t: 150 },
          ],
          ix: 2,
        },
        a: { a: 0, k: [19.5, 19.5, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 39,
      h: 39,
      ip: 90,
      op: 270,
      st: 90,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 119,
      ty: 0,
      nm: 'sm1',
      refId: 'comp_3',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: -45, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 50,
              s: [671.75, 465, 0],
              e: [507.75, 143, 0],
              to: [196.16667175293, -228, 0],
              ti: [-57.6694946289062, 152.2099609375, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 80,
              s: [507.75, 143, 0],
              e: [698.75, -49, 0],
              to: [68.2551498413086, -180.149185180664, 0],
              ti: [0, 0, 0],
            },
            { t: 110 },
          ],
          ix: 2,
        },
        a: { a: 0, k: [19.5, 19.5, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 39,
      h: 39,
      ip: 50,
      op: 230,
      st: 50,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 120,
      ty: 0,
      nm: 'sm1',
      refId: 'comp_3',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: -45, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 10,
              s: [671.75, 465, 0],
              e: [507.75, 143, 0],
              to: [196.16667175293, -228, 0],
              ti: [-57.6694946289062, 152.2099609375, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 40,
              s: [507.75, 143, 0],
              e: [698.75, -49, 0],
              to: [68.2551498413086, -180.149185180664, 0],
              ti: [0, 0, 0],
            },
            { t: 70 },
          ],
          ix: 2,
        },
        a: { a: 0, k: [19.5, 19.5, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 39,
      h: 39,
      ip: 10,
      op: 190,
      st: 10,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 121,
      ty: 0,
      nm: 'sm1',
      refId: 'comp_3',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: -45, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 80,
              s: [671.75, 465, 0],
              e: [564.75, 255, 0],
              to: [-119.833335876465, -73, 0],
              ti: [-45.6694946289062, 61.2099533081055, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 110,
              s: [564.75, 255, 0],
              e: [698.75, -49, 0],
              to: [115.203079223633, -154.404495239258, 0],
              ti: [0, 0, 0],
            },
            { t: 140 },
          ],
          ix: 2,
        },
        a: { a: 0, k: [19.5, 19.5, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 39,
      h: 39,
      ip: 80,
      op: 260,
      st: 80,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 122,
      ty: 0,
      nm: 'sm1',
      refId: 'comp_3',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: -45, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 40,
              s: [671.75, 465, 0],
              e: [564.75, 255, 0],
              to: [-119.833335876465, -73, 0],
              ti: [-45.6694946289062, 61.2099533081055, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 70,
              s: [564.75, 255, 0],
              e: [698.75, -49, 0],
              to: [115.203079223633, -154.404495239258, 0],
              ti: [0, 0, 0],
            },
            { t: 100 },
          ],
          ix: 2,
        },
        a: { a: 0, k: [19.5, 19.5, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 39,
      h: 39,
      ip: 40,
      op: 220,
      st: 40,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 123,
      ty: 0,
      nm: 'sm1',
      refId: 'comp_3',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: -45, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 0,
              s: [671.75, 465, 0],
              e: [564.75, 255, 0],
              to: [-119.833335876465, -73, 0],
              ti: [-45.6694946289062, 61.2099533081055, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 30,
              s: [564.75, 255, 0],
              e: [698.75, -49, 0],
              to: [115.203079223633, -154.404495239258, 0],
              ti: [0, 0, 0],
            },
            { t: 60 },
          ],
          ix: 2,
        },
        a: { a: 0, k: [19.5, 19.5, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 39,
      h: 39,
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 124,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [1170.046, 449.966, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [11.96, 1.48],
                    [2.72, -9.4],
                    [0, 0],
                    [0, 0],
                    [-21.88, -2.16],
                  ],
                  o: [
                    [-11.97, -1.48],
                    [0, 0],
                    [0, 0],
                    [8.54, -3.91],
                    [0.95, 2.52],
                  ],
                  v: [
                    [31.175, -4.027],
                    [21.675, 18.563],
                    [-35.015, 15.393],
                    [-40.505, -14.587],
                    [39.595, -17.487],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.890196025372, 0.878431022167, 0.878431022167, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
}

export default ConversationAnimation
