import React from 'react'
import styled from '@emotion/styled'

import OpenLetterAnimation from 'components/animations/letter/OpenLetterAnimation'
import LottieShape from 'components/animations/LottieShape'

export const OpenLetterAnimationContainer = styled.div`
  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    75% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  animation: fadeOut 2s;
  animation-fill-mode: forwards;
`

export const OpenLetterAnimationElement = () => {
  const DEFAULT_OPEN_LETTER_ANIMATION_SIZE = 500
  const MOBILE_OPEN_LETTER_ANIMATION_SIZE = 300
  const openLetterAnimationSize =
    typeof window !== `undefined` &&
    window.innerWidth &&
    window.innerWidth < DEFAULT_OPEN_LETTER_ANIMATION_SIZE
      ? MOBILE_OPEN_LETTER_ANIMATION_SIZE
      : DEFAULT_OPEN_LETTER_ANIMATION_SIZE

  return (
    <OpenLetterAnimationContainer>
      <LottieShape
        animationData={OpenLetterAnimation}
        size={openLetterAnimationSize}
        loop={false}
      />
    </OpenLetterAnimationContainer>
  )
}
